import { styled } from '@raytion/sri-frontend-ui-components'

type HeroimageProps = {
  url: string
}

const Heroimage = styled.div<HeroimageProps>(
  ({url}) => ({
    width: '100%',
    paddingBottom: 0,
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  })
)

export default Heroimage;