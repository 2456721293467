import React, { useState } from 'react';
import { Box, Text, Flex } from '@raytion/sri-frontend-ui-components'
import InputAutocomplete from './InputAutocomplete';
import DatePicker from './DatePicker';
import ButonSecondary from '../components/ButonSecondary';
import Spinner from './Spinner'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "./Tabs";

import TagBox from './Tag';
import Dialog from "./Dialog";

type TaggingDialogProps = {
  title: string,
  isOpen: boolean,
  onDismiss: ()=>void,
  tagList: string[],
  tagSuggestions: string[],
  onAddTag: (value: string) => void,
  onRemoveTag: (value: string) => void,
  defaultDate: Date | undefined,
  onSubmitDate: (date: Date | undefined) => void,
  tabIndex: number
  handleTabsChange: (index: number) => void,
  blob: string,
  showSpinner: boolean,
  setShowSpinner: any
}

const TaggingDialog: React.FC<TaggingDialogProps> = ({
  title, 
  isOpen, 
  onDismiss, 
  tagList,
  onAddTag, 
  onRemoveTag,
  tabIndex,
  defaultDate,
  handleTabsChange,
  tagSuggestions,
  blob,
  onSubmitDate,
  showSpinner,
  setShowSpinner
}) => {
  const [date, setDate] = useState<Date | undefined>(defaultDate);
  // const [iFrameWidth, setiFrameWidth] = useState("110%");
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  // const [dialog, setDialog] = useState<HTMLElement>();

  const handleSelecteDay = (date: Date | undefined) => {
    setDate(date)
  }
  return(
    <Dialog
      title={title}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab>Preview</Tab>
          <Tab>Tags</Tab>
          <Tab>Custom date</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <Box style={{resize: "both", overflow:"hidden"}}>
                <iframe title="preview" id="preview" style={{
                  backgroundColor: "white", 
                  zIndex: 100, 
                  minWidth: 500, 
                  minHeight: 500 
                }} onLoad={(e) => {
                  const frameDOM = document.getElementById("preview");
                  if(frameDOM){
                    // @ts-ignore
                    frameDOM.contentWindow.scrollTo(0, lastScrollPosition);
                  }
                  // @ts-ignore
                  const iFrameBody = frameDOM.contentWindow.document.getElementsByTagName("body")[0];
                  // @ts-ignore
                  frameDOM.contentDocument.addEventListener("scroll", (event) => {
                    const maxScroll = iFrameBody.scrollTopMax;
                    // @ts-ignore 
                    if (frameDOM && maxScroll <= frameDOM.contentWindow.scrollY && lastScrollPosition !== maxScroll) {
                      setShowSpinner(true);
                      // @ts-ignore
                      setLastScrollPosition(frameDOM.contentWindow.scrollY);
                    }
                  })
                }} height={"100%"} width={"100%"} frameBorder={0} src={blob}></iframe>
              </Box>
              <Flex justifyContent="space-between" mt={3}>
                <Box></Box>
                <Flex>
                  {showSpinner && 
                  <Flex>
                    <Text variant="meta" textColor="primary" mb={4} fontWeight={600} mr={2}>
                        Loading Pages
                    </Text>
                    <Spinner size={13} />
                  </Flex>}
                </Flex>
              </Flex>
            </Box>
          </TabPanel>
          <TabPanel>
            <InputAutocomplete 
              items={tagSuggestions} 
              placeholder="add Tag"  
              onSubmit={ tag => onAddTag(tag)} 
              />
            <Box my={6}>
              <Text variant="meta" textColor="primary" mb={4} fontWeight={600}>
                Current Tags
              </Text>
              {tagList && tagList.map(tag => (
                <TagBox key={tag} value={tag} onRemove={onRemoveTag} mr={2} mb={2} />
              ))}
            </Box>
          </TabPanel>
          <TabPanel>
            <DatePicker 
              selectedDay={date} 
              setSelectedDay={handleSelecteDay} 
            />
            <ButonSecondary 
              mt={3} 
              fullWidht 
              onClick={()=>{
                onSubmitDate(date)
                onDismiss()
              }}>
              Apply
            </ButonSecondary>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Dialog>
  )
}

export default TaggingDialog