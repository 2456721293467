/* eslint-disable no-loop-func */

import { useParams } from "react-router-dom";
import { BlobServiceClient } from '@azure/storage-blob';
import { InteractiveBrowserCredential } from '@azure/identity';
import { msalConfig, getGraphToken } from "../config/msalConfig";
import { config } from "../config/config";
import { useAppState } from '../context/AppProvider'
import JSZip from "jszip";

export const useUpload = () => {
    const { container } = useParams();
    const [state, setState] = useAppState();
    
    const clientId = msalConfig.auth.clientId;
    const tenantId = msalConfig.auth.tenantId;
    const redirectUri = msalConfig.auth.redirectUri;
    const storageUri = "https://oppidataraw.blob.core.windows.net";

    const creds = new InteractiveBrowserCredential({ clientId: clientId, tenantId: tenantId, redirectUri: redirectUri, /*loginStyle: "redirect"*/ });
    const blobServiceClient = new BlobServiceClient(storageUri, creds);

    const triggerIndex = async () => {
        if (state.graphToken) {
            const url = config.api + `index.sri?container=${container}`;
            const headers = { Authorization: ` Bearer ${state.graphToken}` };

            fetch(url, { headers }).then((response) => {
                if(response.status !== 200){
                    getGraphToken().then((response: any) => {
                        if (setState) {
                            setState(state => ({ ...state, graphToken: response }));
                            const headers = { Authorization: ` Bearer ${response}` };
                            fetch(url, { headers }).then((response) => {
                                if (response.status !== 200) {
                                    alert("Something went wrong while index attempt. Please contact your SRI Engineer.");
                                    return;
                                }
                                alert("Index triggered. Your documents will be visible inside search soon!");
                            })
                        }
                    });
                    return;
                }
                alert("Index triggered. Your documents will be visible inside search soon!");
            })
        }
    }

    const getContainers = async () => {
        const containers = [];
        const iter = blobServiceClient.listContainers();
        let containerItem = await iter.next();
        while (!containerItem.done) {
            containers.push(containerItem);
            containerItem = await iter.next();
        }
        return containers;
    }

    const getBlobs = async (targetContainer: string, prefix: string) => {
        const containerClient = blobServiceClient.getContainerClient(targetContainer);
        const iter = containerClient.listBlobsByHierarchy("/", { prefix: prefix === "" ? prefix : prefix + "/" });
        const blobs = [];
        let blobItem = await iter.next();
        while (!blobItem.done) {
            splitPathAndName(blobItem);
            blobs.push(blobItem);
            blobItem = await iter.next();
        }
        return blobs;
    }

    const deleteBlob = async (path: string, reload: () => void, setError: () => void) => {
        try {
            const containerClient = blobServiceClient.getContainerClient(container || "");
            const blockBlobClient = containerClient.getBlockBlobClient(path);
            await blockBlobClient.delete();
            reload();
        }
        catch (e) {
            console.error(e);
            setError();
            reload();
        }
    } 

    const deleteFolder = async (path: string, reload: () => void, setError: () => void) => {
        try {
            const containerClient = blobServiceClient.getContainerClient(container || "");
            const iter = containerClient.listBlobsFlat({ prefix: path });
            let blobItem = await iter.next();
            while (!blobItem.done) {
                const blockBlobClient = containerClient.getBlockBlobClient(blobItem.value.name);
                await blockBlobClient.delete();
                blobItem = await iter.next();
            }
            reload();
        } catch(e){
            console.error(e);
            setError();
            reload();
        }

    }

    const deleteContainer = async (selectedContainer: string, reload: () => void) => {
        const containerClient = blobServiceClient.getContainerClient(selectedContainer);
        await containerClient.delete();
        reload();
    }

    const downloadFolder = async (path: string, name: string, dummyFileName: string, reload: () => void, setError: () => void) => {
        try {
            const zip = new JSZip();
            const containerClient = blobServiceClient.getContainerClient(container || "");
            const iter = containerClient.listBlobsFlat({ prefix: path });
            let blobItem = await iter.next();
            while (!blobItem.done) {
                if (!blobItem.value.name.includes(dummyFileName)) {
                    const blockBlobClient = containerClient.getBlockBlobClient(blobItem.value.name);
                    splitPathAndName(blobItem);
                    const downloadBlockBlobResponse = await blockBlobClient.download();
                    if (downloadBlockBlobResponse && downloadBlockBlobResponse.blobBody) {
                        downloadBlockBlobResponse.blobBody.then(res => {
                            if (blobItem.value) {
                                zip.file(blobItem.value.name, res);
                            }
                        }).catch(e => {
                            console.error(e);
                            setError();
                            reload();
                        });
                    }
                    blobItem = await iter.next();

                }
            }
            zip.generateAsync({ type: "blob" }).then(function (content) {
                const url = URL.createObjectURL(content)
                const a = document.createElement("a");
                document.body.appendChild(a);
                //@ts-ignore
                a.style = "display: none";
                a.href = url;
                a.download = name + ".zip";
                a.click();
                window.URL.revokeObjectURL(url);
                reload();
            }).catch(e => {
                console.error(e);
                setError();
                reload();
            });
        } catch (e) {
            console.error(e);
            setError();
            reload();
        }
    }

    const downloadFile = async (path: string, name: string, reload: () => void, setError: () => void) => {
        const containerClient = blobServiceClient.getContainerClient(container || "");
        const a = document.createElement("a");
        document.body.appendChild(a);
        //@ts-ignore
        a.style = "display: none";
        try {
            const blockBlobClient = containerClient.getBlockBlobClient(path);
            const downloadBlockBlobResponse = await blockBlobClient.download();
            if (downloadBlockBlobResponse && downloadBlockBlobResponse.blobBody) {
                downloadBlockBlobResponse.blobBody.then(res => {
                    const url = URL.createObjectURL(res)
                    a.href = url;
                    a.download = name;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    reload();
                }).catch(e => {
                    console.error(e);
                    setError();
                    reload();
                });
            }
        }
        catch (error) {
            console.error(error.message);
            setError();
            reload();
        }
    }

    const createFolder = async (name: string, breadCrumb: string, dummyFileName: string, reload: () => void) => {
        let path = name;
        if (container) {
            const pathLength = breadCrumb.split(container + "/").length;
            const root = breadCrumb.split(container + "/")[pathLength - 1];
            path = root ? root + "/" + name : name;
        }
        const containerClient = blobServiceClient.getContainerClient(container || "");
        const folder: any = {
            kind: "prefix",
            name: path + "/" + dummyFileName
        }
        try {
            const blockBlobClient = containerClient.getBlockBlobClient(folder.name);
            await blockBlobClient.uploadData(folder);
            reload();
        }
        catch (error) {
            console.error(error.message);
        }
    }

    const splitPathAndName = (blobItem: any) => {
        blobItem.value.objectReplicationMetadata = blobItem.value.name;
        if (blobItem.value.name.includes("/")) {
            const displayNameList = blobItem.value.name.split("/").filter((val: string) => val !== "");
            blobItem.value.objectReplicationMetadata = blobItem.value.name;
            blobItem.value.name = displayNameList[displayNameList.length - 1];
        }
    }

    const uploadBlobs = async (acceptedFiles: File[], generateRelativePath: () => string, reload: () => void, progressCallback: (file: string) => void, setError: () => void) => {
        const containerClient = blobServiceClient.getContainerClient(container || "");
        try {
            const relativePath = generateRelativePath();
            for (const file of acceptedFiles) {
                let currFileRef: any = file;
                currFileRef = currFileRef.path.split("/").filter((res: string) => res !== "").join("/");
                const filePath = relativePath ? relativePath + "/" + currFileRef : currFileRef;
                const blockBlobClient = containerClient.getBlockBlobClient(filePath);
                blockBlobClient.uploadData(file).then(() => {
                    progressCallback(currFileRef);
                }).catch(error => {
                    setError();
                    progressCallback(currFileRef);
                    console.error(error.message);
                })
            }
        }
        catch (error) {
            setError();
            console.error(error.message);
        }
    }

    return { getContainers, getBlobs, deleteBlob, deleteFolder, deleteContainer, downloadFolder, downloadFile, createFolder, uploadBlobs, triggerIndex };
};