/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react';
import { Box, Flex } from '@raytion/sri-frontend-ui-components';

import Header from '../components/Header'
import Footer from '../components/Footer'
import background from '../assets/homepage-bg.png'
import { useAppState } from '../context/AppProvider'
import { Config } from '../config-types'
import url from '../config/url'

type DownloadProps = { config: Config, displayName: string }

const Download: React.FC <DownloadProps> = ({ config, displayName }) => {
  const { search } = useLocation();
  const [{ graphToken }] = useAppState();

  useEffect(()=>{
    if(search && graphToken){
      window.location.assign(`${url}download${search}&access_token=${graphToken}`);
    }
  },[search, graphToken])

  return (
    <Flex flexDirection="column" css={{height: '100vh'}}>
      <Header config={config} displayName={displayName} />
      <Box 
        bg='brand'
        p={4}
        flexGrow={1}
        css={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
          position: 'relative',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
      </Box>
      <Footer />
    </Flex>
  );
}

export default Download;
