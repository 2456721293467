  
/** @jsx jsx */
import { jsx, keyframes } from "@emotion/core";


type SpinnerProps = {
  size?: number,
  label?: string,
  thickness?: string,
  speed?: string,
  color?: string,
  emptyColor?: string,
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 32,
  label = "Loading...",
  thickness = "2px",
  speed = "0.45s",
  color,
  emptyColor = "transparent",
  ...props
}) => {
  return (
    <div
      css={ theme => ({
        display: "inline-block",
        borderWidth: thickness,
        borderColor: "currentColor",
        borderBottomColor: emptyColor,
        borderLeftColor: emptyColor,
        borderRadius: 100,
        borderStyle: "solid",
        color: theme.colors.primary,
        animation: `${spin} ${speed} linear infinite`,
        width: size,
        height: size,
      })}
      {...props}
    >
    </div>
  );
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;


export default Spinner;