import React, { Fragment, cloneElement } from "react";
import { useTooltip, TooltipPopup, Position } from "@reach/tooltip";
import { styled } from '@raytion/sri-frontend-ui-components';
import "@reach/tooltip/styles.css";

const centered: Position = (triggerRect, tooltipRect) => {
  if(triggerRect && tooltipRect){
    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const left = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width - 2;
    return {
      left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
      top: triggerRect.bottom + 8 + window.scrollY,
    };
  }
  return { left: undefined, top: undefined }
};

const Tooltip: React.FC<{label: string; ariaLabel: string, children: any}> = ({ children, label, ariaLabel }) => {
  const [trigger, tooltip] = useTooltip();
  const { isVisible } = tooltip;
  const empty = () => {}
  return (
    <Fragment>
      {cloneElement(children, {...trigger, onMouseDown: empty, onBlur: empty, onFocus: empty})}
      <StyledTooltipPopup
        {...tooltip}
        label={label}
        isVisible={isVisible}
        aria-label={ariaLabel}
        position={centered}
      />
    </Fragment>
  );
}


const StyledTooltipPopup = styled(TooltipPopup)(
  ({ theme }) => ({
    ...theme.textStyles.metaSemibold,
    background: "black",
    color: "white",
    border: "none",
    borderRadius: 4,
    padding: theme.space[1],
    paddingLeft: theme.space[2],
    paddingRight: theme.space[2],
    marginTop: -5,
  })
)

export default Tooltip