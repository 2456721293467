/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import {Box, Text, Link} from '@raytion/sri-frontend-ui-components';
import { Link as RouterLink } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <Box 
      bg='brand'
      p={4}
      css={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        borderTop: '1px solid',
        borderColor: 'rgba(255,2555,255,0.2)',
        position: 'relative',
        height: '100vh',
      }}>
        <Text textColor="white" variant="h2" textAlign="center"> 
          404 Page not found <br></br>
          <Link as={RouterLink} to="/">back to Homepage</Link>
        </Text>
    </Box>
  );
}

export default NotFound;
