/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, {useState} from 'react';
import { CheckboxNavigator as DefaultNavigator, Divider, useTranslation, NavigatorProps, Box, Text, Flex, styled, Label, useNavigator } from '@raytion/sri-frontend-ui-components';
import ShowMoreButton from './ShowMoreButton'
// import ExcludeToggle from './ExcludeToggle'

type Option = {
  value: string,
  name?: string,
  count?: number,
  [key:string]: string | number | undefined
}

const BooleanNavigator:React.FC <NavigatorProps> = ({size = 5, excludeOption = true, ...props}) => {
  const [ showAll, setShowAll ] = useState(false);
  const t = useTranslation();
  const toggleShowAll = () => setShowAll(showAll => !showAll)

  const redactedNavigator = useNavigator({field: "redacted"})
  const privilegedNavigator = useNavigator({field: "privileged"})
  const confidentialNavigator = useNavigator({field: "confidential"})

  const getLabel = (value: string) => {
    if(value.includes("redacted/false")){
      return "Native"
    }
    if(value.includes("redacted/true")){
      return "Redacted"
    }
    if(value.includes("privileged/false")){
      return "Unprivileged"
    }
    if(value.includes("privileged/true")){
      return "Privileged"
    }
    if(value.includes("confidential/false")){
      return "Public"
    }
    if(value.includes("confidential/true")){
      return "Confidential"
    }
  }

  const aggregateValues = () => {
    const allOptions = [...redactedNavigator.options, ...privilegedNavigator.options, ...confidentialNavigator.options].map(option => {
      const enhanced = option
      if(enhanced.name){
        enhanced.name = getLabel(enhanced.value)
      }
      return enhanced
    })
    return allOptions
  }

  const navigatorOptions = aggregateValues()

  return (
    <DefaultNavigator {...props} size={500} filterZeroCounts={false}>
      {({ getCheckboxProps, currentOptions, isExclude, toggleExclude, addValue, removeValue, removeFilter }) => {

        if(!navigatorOptions || navigatorOptions.length === 0){
          return <div></div>
        }

        const selectedItem = navigatorOptions.filter(option => {
          return option.selected;
        }).length !== 0;

        const getNavigator = (value: string) => {
          if(value.includes("redacted/false") || value.includes("redacted/true")){
            return redactedNavigator
          }
          if(value.includes("privileged/false") || value.includes("privileged/true")){
            return privilegedNavigator
          }
          if(value.includes("confidential/false") || value.includes("confidential/true")){
            return confidentialNavigator
          }
        }

        const handleOnClick = (value: string, selected: boolean | undefined) => {
          const navigator = getNavigator(value)
          if(navigator){
            if (!selected) {
              navigator.addValue(value)
              return;
            }
            navigator.removeValue(value);
          }
        }

        const handleRemoveAll = () => {
          redactedNavigator.removeFilter();
          privilegedNavigator.removeFilter();
          confidentialNavigator.removeFilter();
        }

        return (
          <React.Fragment>
          <Box mb={4}>
            <Flex mb={1} justifyContent="space-between" alignItems="center">
              <Text variant="text" pb={1} fontWeight={700}>
                {props.title} 
              </Text>
            </Flex>
            <Box>
              {
                selectedItem && 
                <Label onClick={handleRemoveAll}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text variant="text" css={{ wordBreak: 'break-word', lineHeight: '20px' }}>
                      {'All options'}
                    </Text>
                  </Flex>
                </Label>
              }
              {navigatorOptions.slice(0, showAll ? 500 : size).map( ({value, name, count, selected}:{value: string, name?: string, count?: number, selected?: boolean}) => value && (
                <Label onClick={() => {
                  handleOnClick(value, selected)
                  }} key={value}>
                  <Flex justifyContent="space-between" width='100%'>
                    <Text variant="text" css={{ wordBreak: 'break-word', lineHeight: '20px' }} fontWeight={selected ? 600: 400}>
                      {name ? name : value}
                    </Text>
                    <Box>
                      <Badge>
                        {count}
                      </Badge>
                    </Box>
                  </Flex>
                </Label>
              ))}
            </Box>
            {navigatorOptions.length > 5 && (
              <ShowMoreButton 
                onToggle={toggleShowAll}
                showAll={showAll}
                showMoreLabel={t('Show all')}   
                showLessLabel={t('Show less')}          
              />
            )}
          </Box>
          <Divider backgroundColor="E0E4F2" mb={4} mt={2}  />
          </React.Fragment>
        )
      }}
    </DefaultNavigator>
  )
}

const Badge = styled.div(
  ({theme}) => ({
    ...theme.textStyles.meta,
    marginLeft: theme.space[2],
    paddingLeft: theme.space[2],
    paddingRight: theme.space[2],
    color: theme.colors.primary,
    backgroundColor: '#E0E4F2',
    borderRadius: 50,
    lineHeight: '20px',
  })
)


export default BooleanNavigator;