import { ExcludeToggle, styled } from '@raytion/sri-frontend-ui-components';

const Toggle = styled(ExcludeToggle)(
  ({theme}) => ({
    ...theme.textStyles.meta,
    paddingTop: 1,
    color: theme.colors.primary,
    backgroundColor: '#E0E4F2',
  })
)

export default Toggle