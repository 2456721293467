/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, {useState} from 'react';
import { CheckboxNavigator as DefaultNavigator, useTranslation, Divider, NavigatorProps, Box, Text, Label, Checkbox, Flex, styled } from '@raytion/sri-frontend-ui-components';
import ShowMoreButton from './ShowMoreButton'

type Option = {
  value: string,
  name?: string,
  count?: number,
  [key:string]: string | number | undefined
}

const MultiNavigator:React.FC <NavigatorProps> = ({size = 5, ...props}) => {
  const [ showAll, setShowAll ] = useState(false);
  const t = useTranslation();
  const toggleShowAll = () => setShowAll(showAll => !showAll)

  return (
    <DefaultNavigator {...props} size={500} filterEmptyStrings={false}>
      {({ getCheckboxProps, removeFilter, currentOptions, values }) => {
        const selectedItems = currentOptions.filter((item:any) => item.selected && item.value)
        if(!currentOptions || currentOptions.length === 0){
          return <div></div>
        }

        return (
          <React.Fragment>
          <Box mb={4}>
            <Text variant="text" pb={1} fontWeight={700}>
              {props.title}
            </Text>
            <Box>
              { selectedItems.length > 0 && (
                <React.Fragment>
                  <Label>
                    <Checkbox 
                      {...getCheckboxProps({ value: '' })} 
                      onChange={()=>removeFilter()} 
                      mr={2} 
                      css={{display: 'none'}} 
                    />
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text variant="text" css={{wordBreak: 'break-word', lineHeight: '20px'}}>
                        {'All options'}
                      </Text>
                    </Flex>
                  </Label>
                </React.Fragment>
              )}
              {currentOptions.slice(0, showAll ? 500 : size).map( ({value, name, count}:{value: string, name?: string, count?: number}) => {
                const isSelected = values.includes(value);
                return value && value !== 'f/Source/demo_file' && (
                  <Label>
                    <Checkbox {...getCheckboxProps({ value })} css={{display: 'none'}} />
                    <Flex width="100%" justifyContent="space-between" alignItems="center">
                      <Text variant="text"  css={{wordBreak: 'break-word', lineHeight: '20px'}} textColor={isSelected ? 'primary' : 'text'} fontWeight={isSelected ? 600 : 400}>
                        {name ? name : value}
                      </Text>
                      <Badge>
                        {count}
                      </Badge>
                    </Flex>
                </Label>
              )})}
            </Box>
            {currentOptions.length > 5 && (
              <ShowMoreButton 
                onToggle={toggleShowAll}
                showAll={showAll}
                showMoreLabel={t('Show all')}   
                showLessLabel={t('Show less')}          
              />
            )}
          </Box>
          <Divider backgroundColor="E0E4F2" mb={4} mt={2}  />
          </React.Fragment>
        )
      }}
    </DefaultNavigator>
  )
}

const Badge = styled.div(
  ({theme}) => ({
    ...theme.textStyles.meta,
    marginLeft: theme.space[2],
    paddingLeft: theme.space[2],
    paddingRight: theme.space[2],
    color: theme.colors.primary,
    backgroundColor: '#E0E4F2',
    borderRadius: 50,
    lineHeight: '20px',
  })
)

export default MultiNavigator;