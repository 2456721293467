import { styled, Button as BaseButton } from '@raytion/sri-frontend-ui-components';
import pattern from '../assets/default_pattern.png'

type ButtonProps = {
  fullWidht?: boolean
}


const Button = styled(BaseButton)<ButtonProps>(
  ({theme, fullWidht}) => ({
    ...theme.textStyles.button,
    backgroundImage: `url(${pattern})`,
    backgroundRepeat: 'repeat',
    backgroundColor: '#fff',
    color: theme.colors.brand,
    borderRadius: 0,
    height: 50,
    width: fullWidht ? '100%' : 'auto',
    paddingLeft: theme.space[6],
    paddingRight: theme.space[6],
    transition: '.3s cubic-bezier(.08,.8,0,.84)',
    '&:hover': {
      // background: theme.colors.brand,
      // color: theme.colors.brandContrast,
      background: theme.colors.lightBlue,
    },
    '&:active': {
      // background: theme.colors.brand,
      // color: theme.colors.brandContrast,
      background: theme.colors.lightBlue,
    },
    '&:disabled': {
      color: theme.colors.textDisabled,
      background: theme.colors.neutral[1],
    },
    '&[data-selected]': {
      // background: theme.colors.brand,
      // color: theme.colors.brandContrast,
      background: theme.colors.lightBlue,
    }
  })
)

export default Button