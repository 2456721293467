import { styled } from '@raytion/sri-frontend-ui-components';
import { 
  Tabs as ReachTabs, 
  TabList as ReachTabList, 
  Tab as ReachTab, 
  TabPanels as ReachTabPanels, 
  TabPanel as ReachTabPanel
} from "@reach/tabs";

export const Tabs = styled(ReachTabs)({
  outline: 'none',
})

export const TabList = styled(ReachTabList)({
  display: 'flex',
})

export const Tab = styled(ReachTab)(
  ({theme}) => ({
    display: 'inline-block',
    border: 'none',
    padding: '0.25em 0.5em',
    margin: 0,
    background: 'none',
    color: 'inherit',
    font: 'inherit',
    cursor: 'pointer',
    borderBottom: 'solid 2px transparent',
    outline: 'none',
    ...theme.textStyles.text,
    fontWeight: 400,
    '&[data-selected]': {
      fontWeight: 700,
      borderColor: theme.colors.lightBlue
    }
  })
)

export const TabPanels = styled(ReachTabPanels)(
  ({theme}) => ({
    paddingTop: theme.space[6],
    outline: 'none',
  })
)

export const TabPanel = styled(ReachTabPanel)({
  outline: 'none',
})
