import React from 'react';
import { MarginProps } from 'styled-system';
// import { useParams } from "react-router-dom";
import {
    useBackend,
    Text,
    styled,
    Flex,
    Link,
    useSearchTearm,
    TextProps,
    useParams as frontendParams
} from '@raytion/sri-frontend-ui-components';

export type VerticalNavProps = MarginProps & {
    allLabel?: string,
    resultTabs?: {
        title?: string,
        backend: string
    }[]
}

export const VerticalNav: React.FC<VerticalNavProps> = ({
    resultTabs,
    allLabel = 'All'
}) => {
    const [backend, setBackend] = useBackend()
    const [query] = useSearchTearm()
    // const {project} = useParams()
    const [, setParams] = frontendParams()

    const openBackend = (e: React.MouseEvent, backend: string) => {
        if(!e.ctrlKey && !e.metaKey){
            setParams({sort: ""})
            setBackend(backend)
            e.preventDefault()
        }
    }
    const getBackendUrl = (backend: string) => {
        return window.location.origin + window.location.pathname + "?qs=" + query + "&frontend=" + backend
    }

    if (!resultTabs) return null
    
    return (
        <VerticalBar>
            <Tab selected={backend === ""} backend={""} title={allLabel} openBackend={openBackend} getBackendUrl={getBackendUrl}></Tab>
            {
                resultTabs && resultTabs.map(tab => (
                    <Tab selected={backend === tab.backend} backend={tab.backend} title={tab.title} openBackend={openBackend} getBackendUrl={getBackendUrl}></Tab>
                ))
            }
        </VerticalBar>
    )
}

type TabProps = {
    backend: string,
    title?: string,
    openBackend: (e: React.MouseEvent, backend: string) => void,
    getBackendUrl: (backend: string) => string,
    selected: boolean
}

const Tab = ({ backend, title, openBackend, getBackendUrl, selected}: TabProps) => {
    return (
        <Link style={{textDecoration: "none"}} mr="30px" data-testid={backend ? backend: "all"} key={backend} onClick={e => openBackend(e, backend)} href={getBackendUrl(backend)}>
          <SelectedText fontSize={16} selected={selected} fontWeight={300}>
              {title ? title : backend}
          </SelectedText>
        </Link>
    )
}

const SelectedText = styled(Text)<TextProps & {selected: boolean}>(({ theme, selected }) =>
({
    // fontWeight: selected ? "bold" : "normal",
    textTransform: 'uppercase',
    ...theme.textStyles.text,
    color: theme.colors.text,
    // color: '#fff',
    textDecoration: selected ? "underline": "none",
    textUnderlineOffset: "5px",
    textDecorationThickness: "1px",
}))

const VerticalBar = styled(Flex)(() =>
({
    overflow: "scroll",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
        display: "none"
    }
}))
