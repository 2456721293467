/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useEffect, useState } from 'react';
import { Box, Flex, useLang, useResetSearch, Text, styled } from '@raytion/sri-frontend-ui-components';
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Config } from '../config-types'
import background from '../assets/homepage-bg.png'
import { useAppState } from '../context/AppProvider'
import { useHistory } from "react-router-dom";

type HomePageProps = { config: Config, displayName: string }

const HomePage: React.FC <HomePageProps> = ({config, displayName}) => {
  const [lang] = useLang();
  const resetSearch = useResetSearch();
  const [state] = useAppState();
  const [projects, setProjects] = useState(null);
  const history = useHistory();

  const fetchProjects = () => {
    if (state.graphToken) {
      const url = config.api + `user/projects`;
      const headers = { Authorization: ` Bearer ${state.graphToken}` };

      fetch(url, { headers }).then((response : any) => {
        response.json().then((projects:any) => {
          setProjects(projects)
        })
      })
    }
  }

  useEffect(()=>{
    fetchProjects();
    resetSearch({lang})
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <Flex flexDirection="column" css={{height: '100vh'}}>
      <Header config={config} displayName={displayName} />
      <Box 
        bg='brand'
        p={4}
        flexGrow={1}
        css={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
          position: 'relative',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <Box bg="white" p={5} mt={-200} style={{
          boxShadow: "5px 5px 15px 5px #000000",
          borderRadius: "5px"
        }}>
          <Flex justifyContent="space-between" mt={3}>
            <div></div>
            <Text variant="button" fontWeight={300} css={{ textTransform: 'uppercase' }}>
              Choose a project
                </Text>
            <div></div>
          </Flex>
          {projects && (
            <ProjectBox mt={5} mb={5} mr={150}>
              {
              //@ts-ignore
              projects.map((project: any, i: number) => {
                const frontend = project.sriFrontend.filter((frontend: string[]) => !frontend.includes("_mail") && !frontend.includes("_doc"))
                return (
                  <ProjectBrowseRow justifyContent="space-between" key={project.sriFrontend + i} style={{ cursor: "pointer" }} onClick={(e) => { history.push("/" + frontend + "/search")}}>
                    <div></div>
                    <BrowsItemName>
                      <Text fontWeight={600} css={{ textTransform: 'uppercase' }}>{frontend}</Text>
                    </BrowsItemName>
                    <div></div>
                  </ProjectBrowseRow>
                );
              })}
            </ProjectBox>
          )}
          { !projects &&
            <Text fontWeight={600} css={{ textTransform: 'uppercase' }}>No project assignment found</Text>
          }
        </Box>
      </Box>
      <Footer />
    </Flex>
  );
}

const ProjectBrowseRow = styled(Flex)(
  ({ theme }) => ({
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 5,
    paddingLeft: 20,
    alignItems: "center",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.colors.lightBlue,
    '&:hover': {
      backgroundColor: theme.colors.lightBlueHover,
      '& .icon-hover': {
        color: theme.colors.primary,
      }
    },
  })
)

const ProjectBox = styled(Box)(
  ({ theme }) => ({
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.colors.lightBlue,
    width: "100%",
    maxHeight: "225px",
    overflow: "scroll"
  })
)

const BrowsItemName = styled(Text)(
  ({ theme }) => ({
    marginRight: 10,
  })
)

export default HomePage;
