/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Text, Icon, TextButton, styled, Flex} from '@raytion/sri-frontend-ui-components'

type BreadcrumbProps = {
    breadCrumb: string
}


const Breadcrumb: React.FC<BreadcrumbProps> = ({breadCrumb}) => {

    const location = useLocation();
    const {project} = useParams();
    const history = useHistory();

    const fullBreadCrumb = breadCrumb.split('/').filter(obj=>obj);
    const currentFolder = fullBreadCrumb[fullBreadCrumb.length-1];

    const handleOnClick = (path:string) => {
        let newLocation = location.pathname.split(path)[0]+path;
        if (path === '/') {
            if (project) {
                newLocation = '/'+project+'/upload/'
            } else {
                newLocation = '/upload/'
            }
        }
        history.push(newLocation);
    }

    return (
        <Flex ml={20} >
            {fullBreadCrumb.map((breadCrumbContent, i) => {
                const path = fullBreadCrumb.slice(1,i+1).join("/")+'/';
                const isCurrentFolder = breadCrumbContent === currentFolder && i === fullBreadCrumb.length-1;

                if (isCurrentFolder) {
                    return (
                    <TextStyled key={breadCrumb+i}>
                        {breadCrumbContent}
                    </TextStyled> 
                    );
                } 

                return (                
                    <Flex key={breadCrumb + i}>
                        <TextButtonStyled onClick={() => handleOnClick(path)} variant="link">
                            {breadCrumbContent}
                        </TextButtonStyled>
                        <IconStyled className="fal fa-chevron-right" />
                    </Flex>
                    );
                }
            )   
            }
        </Flex>
    );
}

const TextButtonStyled = styled(TextButton)(({theme}) => ({
    color: theme.colors.primary,
    marginLeft: 5,
    marginRight: 5,
    marginTop: -3,
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: 400,
    fontSize: 12,
    '&:hover': {
        color: theme.colors.primaryHover,
        textDecoration: 'none'
    }
}));

const TextStyled = styled(Text)(({theme}) => ({
    color: theme.colors.brand,
    marginLeft: 5,
    marginRight: 5,
    marginTop: -2,
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: 400,
    fontSize: 12
}));

const IconStyled = styled(Icon)(({theme}) => ({
    color: theme.colors.primary,
    marginTop: 7,
    fontSize: 9
}));



export default Breadcrumb;