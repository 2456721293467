import React from 'react';
import { Pagination as DefaultPagination, Flex, TextButton, IconButton, styled, Icon } from '@raytion/sri-frontend-ui-components'

const Pagination:React.FC = () => {
  return(
    <DefaultPagination
      onChange={()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }}
    >
      {({
          getButtonProps,
          next,
          prev,
          range,
          current, 
        })=>(
          <Flex>
            <NavButton {...getButtonProps({page: prev})}>
              <Icon size={14} className="fal fa-chevron-left" />
            </NavButton>
            {
              range.map( (page, index) => page === '...' ? (
                <PageButton key={index} mx={1} disabled={true}>
                {page.toString()}
                </PageButton> 
              ):(
                <PageButton key={index} mx={1} {...getButtonProps({page})}>
                {page.toString()}
                </PageButton>
              )          
            )}
            <NavButton {...getButtonProps({page: next})}>
              <Icon size={14} className="fal fa-chevron-right" />
            </NavButton>
          </Flex>
        )}
    </DefaultPagination>
  )
}

export default Pagination


const NavButton = styled(IconButton)(
  ({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    width: 25,
    height: 25,
    padding: '0 8px',
    '&:hover': {
      color: theme.colors.primary,
    },
    '&:active': {
      color: theme.colors.primary,
    },
    '&:disabled': {
      color: theme.colors.primary,
      opacity: 0.5
    },
    '&[data-selected]': {
      color: theme.colors.primary,
    },
  })
)


const PageButton = styled(TextButton)(
  ({ theme }) => ({
    width: 25,
    height: 25,
    margin: 0,
    fontWeight: 600,
    borderRadius: 100,
    paddingBottom: '1px',
    paddingLeft: '1px',
    marginLeft: 2,
    '&:hover': {
      color: theme.colors.primary,
    },
    '&:active': {
      color: theme.colors.primary,
      backgroundColor: theme.colors.lightBlue,
    },
    '&[aria-current="true"]': {
      color: theme.colors.primary,
      backgroundColor: theme.colors.lightBlue,
    },
  })
)