import React from 'react';
import { margin, MarginProps } from 'styled-system'
import { styled, Icon, BaseButton, Text } from '@raytion/sri-frontend-ui-components'

type TagProps = StyledTagProps & {
  value: string,
  onRemove: (value:string) => void
}

const Tag: React.FC <TagProps> = ({value, onRemove, ...props}) => {
  return (
    <StyledTag {...props} onClick={()=> onRemove(value)}>
      <Text variant="meta" as="span" textColor="primary" mr={1}>{value}</Text>
      <Icon size={14} className="fal fa-times" />
    </StyledTag>
  )
}

export default Tag

type StyledTagProps = MarginProps

const StyledTag = styled(BaseButton)<StyledTagProps>(
  ({theme}) => ({
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: theme.space[2],
    paddingRight: theme.space[2],
    color: '#395a835c',
    backgroundColor: theme.colors.lightBlue,
    borderRadius: 50,
    height: '20px',
    '&:hover': {
      color: theme.colors.primary,
    }
  }),
  margin
)