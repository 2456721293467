import React, { useState } from 'react';
import { margin, MarginProps } from 'styled-system'
import { styled, BaseButton, Icon } from '@raytion/sri-frontend-ui-components'
import { pick, omit } from '@styled-system/props'

export type DateNavigatorInputProps = RootProps & React.HTMLProps<HTMLInputElement> & {
  onRemove?: () => void,
}

export const DateNavigatorInput = (props: DateNavigatorInputProps ) => {
  const [touched, setTouched] = useState(false);
  const {onRemove, ...rest} = props;
  const rootProps = pick(rest)
  const {onBlur, ...inputProps} = omit(rest)
  return(
    <Root {...rootProps}>
      <StyledInput 
        data-touched={touched ? true : undefined} 
        type="text" 
        pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}" 
        {...inputProps} 
        onBlur={(e)=> {
          onBlur(e);
          !e.currentTarget.value ? setTouched(false): setTouched(true);
        }}
      />
      {inputProps.value ? (
        <IconButton onClick={onRemove}>
          <Icon className="fal fa-times-circle" />
        </IconButton>
      ):(
        <IconWrapper>
          <Icon className="fal fa-calendar" />
        </IconWrapper>
      )}
    </Root>
  ) 
};


type RootProps =  MarginProps

const Root = styled.div<RootProps>(
  {
    position: 'relative',
  },
  margin,
)

const IconButton = styled(BaseButton)(
  ({ theme }) => ({
    position: 'absolute', 
    top:0, 
    paddingTop: 2, 
    backgroundColor: 'transparent',
    right: theme.space[1], 
    color: theme.colors.primary, 
    height: '100%'
  }),
)

const IconWrapper = styled.div(
  ({ theme }) => ({
    position: 'absolute', 
    top:0, 
    paddingTop: 8, 
    right: theme.space[1], 
    color: theme.colors.primary, 
    height: '100%'
  }),
)

const StyledInput = styled.input(
  ({ theme }) => ({
    width: '100%',
    margin: 0,
    border: 0,
    borderBottom: '1px solid',
    borderColor: theme.colors.neutral[3],
    padding: 0,
    paddingLeft: theme.space[2],
    paddingRight: theme.space[2],
    height: 32,
    outline: 'none',
    position: 'relative',
    ...theme.textStyles.label,
    backgroundColor: 'white',
    '&::placeholder': {
      color: theme.colors.lightBlue
    },
    '&:focus':{
      borderBottom: '1px solid',
      borderColor: theme.colors.primary,
    },
    '&[data-selected]':{
      borderBottom: '1px solid',
      borderColor: theme.colors.primary,
    },
    '&[data-touched]:invalid': {
      borderBottom: '1px solid',
      borderColor: theme.colors.red,
      boxShadow:'none'
    },
  }),
  
)