
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import { styled, Flex, Link, Text } from '@raytion/sri-frontend-ui-components'
import { ReactComponent as Logo} from '../assets/marex-logo.svg'

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <Flex 
        justifyContent="space-between" 
        alignItems="center" 
        css={{margin: '0 auto', maxWidth: 1180, height: '100%'}}
        px={8}     
      >
        <Flex alignItems="center" >
          <Logo />
        </Flex>
        <Flex>
          <Text fontWeight={700} textColor="white" mr={4}>
            <Link href="https://mareex.eu/" target="_blank">
              www.mareex.eu
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

const Wrapper = styled.div(
  ({theme})=>({
    height: 90,
    backgroundColor: 'black'
  })
)

export default Footer