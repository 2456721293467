import React, {useState} from 'react';
import Downshift, {} from 'downshift';
import { styled, Icon, Text } from '@raytion/sri-frontend-ui-components'


type InputAutocomplete = {
  placeholder: string,
  items: string[],
  onSubmit: (value: string) => void
}

const InputAutocomplete: React.FC <InputAutocomplete> = ({ placeholder, items, onSubmit }) => {
  const [inputValue, setInputValue] = useState('');

  const stateReducer = (state: any, changes: any) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        setTimeout(()=>{onSubmit(changes.selectedItem)}, 200);
        setInputValue('')
        return {
          ...changes,
          selectedItem: null,
          isOpen: false,
          highlightedIndex: null,
        }
      case Downshift.stateChangeTypes.keyDownArrowUp:
      case Downshift.stateChangeTypes.keyDownArrowDown:
        let { highlightedIndex } = changes;
        if (state.highlightedIndex !== null) {
          if(Math.abs(state.highlightedIndex - highlightedIndex) > 1){
            highlightedIndex = null
          }
        }
        return {
          ...changes,
          highlightedIndex: highlightedIndex !== undefined ? highlightedIndex : null,
        } 
      default:
        return changes
    }
  }

  return(
    <Downshift stateReducer={stateReducer} inputValue={inputValue} onInputValueChange={setInputValue}>
      {({
        getInputProps,
        getItemProps,
        isOpen,
        highlightedIndex,
        closeMenu
      }) => {
        const filteredItems = items.filter((item : string) => !inputValue || item.includes(inputValue.toLowerCase()))
        const noHighlighting= !highlightedIndex && highlightedIndex !== 0
        return (
          <div style={{ position: 'relative' }}>
            <InputWrapper>
              <Input
                {...getInputProps({
                  isOpen,
                  placeholder,
                  onKeyDown: (event: any) => {
                    if(noHighlighting){
                      switch (event.key) {
                        case "Enter": {
                          closeMenu();
                          if(inputValue){
                            setTimeout(()=>{onSubmit(inputValue)}, 200);
                            setInputValue('')
                          };
                          break;
                        }
                      }
                    }
                  },
                })}
              />
              <Icon color={(noHighlighting && inputValue) ? 'primary' :'lightBlue'} size={20} className="fal fa-plus-circle" />
            </InputWrapper>
            {isOpen &&
              !!filteredItems.length && (
                <Menu>
                  {filteredItems.map((value, index) => (
                    <Option
                      {...getItemProps({
                        key: value,
                        index,
                        item: value,
                      })}
                    >
                      <Text textColor="text">{value}</Text>
                      <Icon size={20} className="fal fa-plus-circle" />
                    </Option>
                  ))}
                </Menu>
              )}
          </div>
        )
      }}
    </Downshift>
  )
}

export default InputAutocomplete

const InputWrapper = styled.div(
  ({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderColor: theme.colors.lightBlue,
    paddingLeft: theme.space[0],
    paddingRight: theme.space[4],
  })
)

const Input = styled.input(
  ({theme}) => ({
    ...theme.textStyles.text,
    width: '100%',
    height: 50,
    border: 'none',
    outline:'none',
  })
)

const Menu = styled.ul(
  ({theme}) => ({
    zIndex: 200,
    paddingTop: theme.space[2],
    paddingBottom: theme.space[2],
    padding: 0,
    marginTop: 0,
    position: 'absolute',
    width: '100%',
    maxHeight: 200,
    overflowY: 'auto',
    overflowX: 'hidden',
    outline: 0,
    border: '1px solid',
    backgroundColor: 'white',
    borderColor: theme.colors.lightBlue,
  })   
)

const Option = styled.li(
  ({theme}) => ({
    ...theme.textStyles.text,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.space[4],
    paddingRight: theme.space[4],
    backgroundColor: 'white',
    color: theme.colors.lightBlue,
    '&[aria-selected=true]': {
      backgroundColor: theme.colors.lightBlue,
      color: theme.colors.primary,
    }
  }) 
)