import React from 'react';
import { Searchbox as DefaultSearchbox, Flex, styled, Icon, SearchboxInput, SearchboxButton } from '@raytion/sri-frontend-ui-components'
// import Button from './Button';

const Searchbox: React.FC = () => {
  return (
    <DefaultSearchbox>
      {()=>(
      <Flex>
        <InputWrapper>
          <SearchInput placeholder="search" />
          <Icon size={20} className="fal fa-search" />
        </InputWrapper>
        <Button>
          SEARCH
        </Button>
      </Flex>
      )}
    </DefaultSearchbox>
  )
}

export default Searchbox;

const InputWrapper = styled.div( 
  ({ theme }) => ({
    display: 'flex',
    opacity: 1,
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    padding: 0,
    paddingLeft:  theme.space[4],
    paddingRight: theme.space[4],
    backgroundColor: theme.colors.surface,
}))

const SearchInput = styled(SearchboxInput)( 
  ({ theme }) => ({
    margin: 0,
    border: 'none',
    padding: 0,
    height: 50,
    outline: 'none',
    position: 'relative',
    ...theme.textStyles.text,
    width: '100%',
    backgroundColor: theme.colors.surface,
    '&::placeholder': {
      color: theme.colors.textLight
  }
}))

const Button = styled(SearchboxButton)( 
  ({ theme }) => ({
    ...theme.textStyles.button,
    borderRadius: 0,
    height: 50,
    paddingLeft: theme.space[6],
    paddingRight: theme.space[6],
    backgroundColor: theme.colors.brand,
    color: theme.colors.brandContrast,
    '&:hover': {
      backgroundColor: theme.colors.brand,
    },
    '&:active': {
      backgroundColor: theme.colors.brand,
    },
    '&:disabled': {
      color: theme.colors.textDisabled,
      backgroundColor: theme.colors.neutral[1],
    },
    '&[data-selected]': {
      backgroundColor: theme.colors.brand,
      color: theme.colors.brand,
    }
}))