export const languages = {
  "en": {
    "What are you looking for?": "What are you looking for?",
    "Search": "Search",
    "search": "search",
    "Filters": "Filters",
    "clear all": "Clear all",
    "Remove all filters": "Remove all filters",
    "hide": "hide",
    "%1 results for %2": "%1 results for %2",
    "Your query \"%1\" returned 0 results.": "Your query \"%1\" returned 0 results.",
    "Did you mean": "Did you mean",
    "There are no filters to show.": "There are no filters to show.",
    "Search hints": "Search hints",
    "All options": "All options",
    "Show all": "Show all",
    "Show less": "Show less",
    "Sort by": "Sort by",
    "Relevance": "Relevance",
    "No matching options": "No matching options",
    "Put a plus sign in front of your search term to explicitly include a term, e.g., '+contract correspondence'.": "Put a plus sign in front of your search term to explicitly include a term, e.g., '+contract correspondence'.",
    "Put a minus sign in front of your search term to explicitly exclude a term, e.g., 'contract -archive'.": "Put a minus sign in front of your search term to explicitly exclude a term, e.g., 'contract -archive'.",
    "For phrase search, surround multiple terms with quotation marks, e.g., '\"John Doe\"'.": "For phrase search, surround multiple terms with quotation marks, e.g., '\"John Doe\"'.",
    "With colon restrict the search to title, keywords or description, e. g. 'title:Raytion'.": "With colon restrict the search to title, keywords or description, e. g. 'title:Raytion'.",
  },
  "de": {
    "What are you looking for?": "Wonach suchen Sie?",
    "Search": "Suchen",
    "search": "suchen",
    "Filters": "Filter",
    "clear all": "Alle entfernen",
    "Remove all filters": "Entferne alle Filter",
    "hide": "ausblenden",
    "%1 results for %2": "%1 Ergebnisse für %2",
    "Your query \"%1\" returned 0 results.": "Ihre Suche \"%1\" ergab 0 Treffer.",
    "Did you mean": "Meinten Sie",
    "There are no filters to show.": "Es sind keine Filter zum Anzeigen vorhanden.",
    "Search hints": "Suchhinweise",
    "All options": "Alle Optionen",
    "Show all": "Alle anzeigen",
    "Show less": "Weniger anzeigen",
    "Sort by": "Sortieren nach",
    "Relevance": "Relevanz",
    "No matching options": "Keine passenden Optionen",
    "Put a plus sign in front of your search term to explicitly include a term, e.g., '+contract correspondence'.": "Setzen Sie ein Pluszeichen vor einen Suchbegriff, um den Begriff explizit einzuschließen, z.B. '+vertrag korrespondenz'.",
    "Put a minus sign in front of your search term to explicitly exclude a term, e.g., 'contract -archive'.": "Setzen Sie ein Minuszeichen vor einen Suchbegriff, um den Begriff explizit auszuschließen, z.B. 'vertrag -archiv'.",
    "For phrase search, surround multiple terms with quotation marks, e.g., '\"John Doe\"'.": "Umschließen Sie mehrere Begriffe mit Anführungszeichen, um eine Phrasensuche durchzuführen, z.B. '\"Max Mustermann\"'.",
    "With colon restrict the search to title, keywords or description, e. g. 'title:Raytion'.": "Mit Doppelpunkt können Sie die Suche auf title, keywords oder description beschränken, z. B. 'title:Raytion'."
  }
}