
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState } from 'react';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment'
import DayPicker,{ LocaleUtils } from 'react-day-picker';
import { Flex, IconButton, Icon, Text, styled, TextButton as DefaultTextButton, IconButtonProps } from '@raytion/sri-frontend-ui-components';
import 'react-day-picker/lib/style.css';
import 'moment/locale/de';

type DatePickerProps = {
  selectedDay: Date | undefined,
  setSelectedDay: (date: Date | undefined) => void
}

export const DatePicker: React.FC<DatePickerProps> = ({selectedDay, setSelectedDay}) => {
  const [monthDate, setMonthDate] =  useState(new Date());
  // const [selectedDay, setSelectedDay] =  useState<Date | undefined>(undefined);
  
  const monthList = selectedDay ?  MomentLocaleUtils.getMonths() : undefined;
  const month = selectedDay ? monthList[selectedDay.getMonth()] : undefined;
  const year = selectedDay ? selectedDay.getFullYear() : undefined;
  const day = selectedDay ? selectedDay.getDate() : undefined;
  const weekday = selectedDay ? selectedDay.getDay() : undefined;
  const weekdayName = selectedDay ?  MomentLocaleUtils.formatWeekdayLong(weekday) : undefined;

  const handleYearMonthChange = (date:Date) => {
    setMonthDate(date)
  }

  const handleDateChange = (date:Date ) => {
    setSelectedDay(date)
    handleYearMonthChange(date)
  }

  const handleNextMonth = () => {
    handleDateChange(moment(selectedDay).add(1, 'M').toDate())
  };

  const handlePrevMonth = () => {
    handleDateChange(moment(selectedDay).add(-1, 'M').toDate())
  };

  const handleNextYear= () => {
    handleDateChange(moment(selectedDay).add(1, 'year').toDate())
  };

  const handlePrevYear = () => {
    handleDateChange(moment(selectedDay).add(-1, 'year').toDate())
  };

  const handleNextDay= () => {
    handleDateChange(moment(selectedDay).add(1, 'd').toDate())
  };

  const handlePrevDay = () => {
    handleDateChange(moment(selectedDay).add(-1, 'd').toDate())
  };

  return(
    <Flex>
      <Flex bg="primary" width="50%" flexDirection="column">
        <Flex bg="#365479" justifyContent="center" alignItems="center" css={{height: 40}} >
          <Text textColor="white" variant="meta" fontWeight={700}>
            {weekdayName ? weekdayName : 'no date selected'}
          </Text>
        </Flex>
        <Flex 
          flexDirection="column"
          justifyContent="center" 
          flexGrow={1}
        >
          {selectedDay && (
            <React.Fragment>
              <Flex justifyContent="space-between" alignItems="center" px={8}>
                <NavLeft textColor="#E0E4F2" onClick={handlePrevMonth}/>
                <Text textColor="white" variant="meta" fontWeight={700}>
                  {month}
                </Text>
                <NavRight textColor="#E0E4F2" onClick={handleNextMonth}/>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center" px={8}>
                <NavLeft textColor="#E0E4F2" onClick={handlePrevDay}/>
                <Text  textColor="white" variant="h1" fontSize={70} css={{lineHeight: 1.2}}>
                  {day}
                </Text>
                <NavRight textColor="#E0E4F2" onClick={handleNextDay}/>
              </Flex>
            <Flex justifyContent="space-between" alignItems="center" px={8}>
              <NavLeft textColor="#E0E4F2"onClick={handlePrevYear}/>
              <Text textColor="white" fontWeight={400} fontSize={16}>
                {year}
              </Text>
              <NavRight textColor="#E0E4F2" onClick={handleNextYear}/>
            </Flex>
          </React.Fragment>
          )}
        </Flex>
        <Flex css={{height: 32}} justifyContent="center" alignContent="center">
          {selectedDay && (
            <TextButton variant="subtle" onClick={()=>setSelectedDay(undefined)}>
              remove date
            </TextButton>
          )}
        </Flex>
      </Flex>
      <Flex width="50%" css={{height: 260}} justifyContent="center">
        <StyledDatePicker>
          <DayPicker
            localeUtils={MomentLocaleUtils}
            captionElement={({ date, localeUtils }) => (
              <Header
                date={date}
                locale={localeUtils}
                onChange={handleYearMonthChange}
              />
            )}
            selectedDays={selectedDay}
            onDayClick={handleDateChange}
            firstDayOfWeek={1}
            navbarElement={()=> null}
            numberOfMonths={1}
            month={monthDate}
            showWeekNumbers={false}
            showOutsideDays={false}
          />
        </StyledDatePicker>
      </Flex>
    </Flex>
  )
}

export default DatePicker

type HeaderProps = {
  date: Date,
  locale: LocaleUtils,
  onChange: (date: Date, month: string) => void
}

const Header: React.FC <HeaderProps> = ({date, locale, onChange}) => {
  const monthList = locale.getMonths();
  const month = monthList[date.getMonth()];
  const year = date.getFullYear();
  
  const handleNextMonth = () => {
    onChange(moment(date).add(1, 'M').toDate(), month)
  };

  const handlePrevMonth = () => {
    onChange(moment(date).add(-1, 'M').toDate(), month)
  };

  return(
    <StyledHeader>
      <Flex justifyContent="space-between" alignItems="center">
        <NavLeft onClick={handlePrevMonth}/>
        <Text textColor="primary" variant="meta" fontWeight={700}>
        {`${month} ${year}`}
        </Text>
        <NavRight onClick={handleNextMonth}/>
      </Flex>
    </StyledHeader>
  )
}

const NavLeft: React.FC<IconButtonProps & {textColor?: string}> = ({textColor = 'inherit', ...props}) => (
  <IconButton css={{height: 32, width: 'auto'}} variant="default" {...props}>
    <Icon color={textColor} className="fal fa-chevron-left"/>
  </IconButton>
)

const NavRight: React.FC<IconButtonProps & {textColor?: string}> = ({textColor = 'inherit', ...props}) => (
  <IconButton css={{height: 32, width: 'auto'}} variant="default" {...props}>
    <Icon color={textColor} className="fal fa-chevron-right"/>
  </IconButton>
)


const TextButton = styled(DefaultTextButton)(
  ({theme}) => ({ 
    ...theme.textStyles.meta,
    fontWeight: 700,
    color: theme.colors.lightBlue,
    '&:hover': {
      color: theme.colors.lightBlue,
    },
    '&:active': {
      color: theme.colors.lightBlue,
    }
  })
)


const StyledDatePicker = styled.div(
  ({theme}) => ({
    position: 'relative',
    '& .DayPicker-wrapper': {
      outline: 'none',
    },
    '& .DayPicker-Month': {
      margin: 0,
      outline: 'none',
    },
    '& .DayPicker-Day--selected':{
      backgroundColor: `${theme.colors.primary} !important`,
      color: `${theme.colors.primaryContrast} !important`,
      borderRadius: '100px',
    },
    '& .DayPicker-Day':{
      outline: 'none',
      width: 30,
      height: 30,
      ...theme.textStyles.label,
    },
    '& .DayPicker-Weekday':{
      ...theme.textStyles.label,
    },
  })
)

const StyledHeader = styled.div({
  display: 'table-caption',
  marginBottom: '.5em',
  padding: '0 .5em',
})