/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import { useUploadState } from '../context/UploadProvider';
import { Flex, Box, Text, Menu, MenuButton, MenuList, MenuItem, Icon, styled } from '@raytion/sri-frontend-ui-components';

type FileBrowserProps = {

}

// const textSuffix = [".docx", ".txt"];

const FileBrowser: React.FC<FileBrowserProps> = () => {
    const { browserContext, handleNavigation, deleteOperation, download } = useUploadState();
    const getIconName = (fileNameFull: string): string => {
        const fileSuffix = fileNameFull.split('.');
        let extension = fileSuffix[fileSuffix.length - 1] ? fileSuffix[fileSuffix.length - 1] : 'default';
        switch (extension) {
            case 'zip':
                return 'fal fa-file-archive';
            case 'pdf':
            case 'PDF':
                return 'fal fa-file-pdf';
            case 'txt':
                return 'fal fa-file-alt';
            case 'docx':
            case 'doc':
                return 'fal fa-file-word';
            case 'ppt':
            case 'pptx':
                return 'fal fa-file-powerpoint';
            case 'xlsx':
            case 'xls':
                return 'fal fa-file-excel';
            case 'png':
            case 'jpg':
            case 'svg':
            case 'eps':
            case 'bmp':
                return 'fal fa-file-image';
            case 'mp4':
                return 'fal fa-file-video';
            default:
                return 'fal fa-file';
        }
    }

    const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const formatDate = (fullDate: string) => {
        const justDate = fullDate.split("T");
        const splitDate = justDate[0].split("-", 3);
        return splitDate[2] + '.' + splitDate[1] + '.' + splitDate[0].substring(1) + ' CET';
    }

    return (
        <Box mt={1}>
            <Box>
                <FileBrowsHead>
                    <Flex flex={13}>
                        <Text>Name</Text>
                    </Flex>
                    <Flex flex={3}>
                        <Text>Size</Text>
                    </Flex>
                    <Flex flex={5}>
                        <Text>Upload date</Text>
                    </Flex>
                </FileBrowsHead>
            </Box>
            <Box mb={10}>
            {browserContext.contents.filter((container: any) => {
                if (browserContext.contents.length !== 1 && container.value.name === browserContext.dummyFileName && browserContext.uploadState !== "UPLOADING" && browserContext.uploadState !== "DELETING") {
                    deleteOperation(container.value.objectReplicationMetadata, false);
                }
                return container.value.name !== browserContext.dummyFileName;
            }).map((container: any, i: number) => {
                let isFolder = true;
                if (container && container.value && container.value.objectReplicationMetadata) {
                    isFolder = container.value.objectReplicationMetadata.endsWith("/");
                }

                return (
                    <FileBrowsRow key={container.value.name + i}>
                        <Flex flex={13} style={{ cursor: "pointer" }} onClick={(e) => {
                            isFolder ? handleNavigation(container.value.name) : download(container.value.objectReplicationMetadata, container.value.name, false)
                        }}>
                            { isFolder &&
                                <BrowsItemIcon className="fal fa-folder" size={20}  />
                            } 
                            { !isFolder &&
                                <BrowsItemIcon className={getIconName(container.value.name)} size={20}  />
                            }       
                            <BrowsItemName>
                                {isFolder ? <Text style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "30vw" }} fontWeight={600}>{container.value.name}</Text> :
                                    <Text style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "30vw" }}>{container.value.name}</Text>}
                            </BrowsItemName>
                        </Flex>

                        <Flex flex={3}>
                            <BrowsItemInfo>
                                {!isFolder ? formatBytes(container.value.properties.contentLength) : ""}
                            </BrowsItemInfo>
                        </Flex>
                        <Flex flex={4}>
                            <BrowsItemInfo>
                                {!isFolder ? formatDate(JSON.stringify(container.value.properties.lastModified)) : ""}
                            </BrowsItemInfo>
                        </Flex>  
                        {
                            !browserContext.showingContainers &&
                            <Flex flex={1} zIndex={100}
                                className="TEST">
                                <Menu>
                                    <BrowsItemContextMenu arrow={false} className="icon-hover">
                                        <Icon className="fal fa-ellipsis-v" size={25} />
                                    </BrowsItemContextMenu>
                                    <MenuList position="right" px={5} py={3}>
                                            <MenuItem 
                                                key={1}
                                                onSelect={() => {
                                                    download(container.value.objectReplicationMetadata, container.value.name, isFolder)
                                                }}
                                                css={{
                                                    backgroundColor: "transparent !important",
                                                    '&:hover': { 
                                                        backgroundColor: "transparent",
                                                }
                                                }}
                                            >
                                                <Text className="ExpandedOption" variant="button" css={{
                                                    color: "#202020",
                                                    '&:hover': {
                                                        color: "#395A83",
                                                    }
                                                }}>
                                                    Download
                                                </Text>
                                            </MenuItem>
                                        {/* {
                                        hasWritePermissions() && 
                                            <MenuItem
                                            key={2}
                                            onSelect={() => {
                                                deleteOperation(container.value.objectReplicationMetadata, isFolder)
                                            }}
                                            css= {{
                                                backgroundColor: "transparent !important",
                                                '&:hover': { 
                                                    backgroundColor: "transparent",
                                                }
                                            }}
                                            >
                                                <Text 
                                                variant="button" 
                                                css={{ 
                                                    color: "#202020",
                                                    '&:hover': {
                                                        color: "#C60604",
                                                    }
                                                }}
                                                >
                                                    Delete
                                                </Text>
                                            </MenuItem>
                                        } */}
                                    </MenuList>
                                </Menu>
                            </Flex>
                        }
                     
                    </FileBrowsRow>
                );
            })}
            </Box>
        </Box>
    );
}


const FileBrowsRow = styled(Flex)(
    ({ theme }) => ({
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 5,
        paddingLeft: 20,
        alignItems: "center",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: theme.colors.lightBlue,
        '&:hover': {
        backgroundColor: theme.colors.lightBlueHover,
        '& .icon-hover' : {
                color: theme.colors.primary,
            }
        },
    })
)

const FileBrowsHead = styled(Flex)(
    ({ theme }) => ({
        paddingBottom: 15,
        paddingRight: 5,
        paddingLeft: 20,
        alignItems: "center",
        borderBottomWidth: "2px",
        borderBottomStyle: "solid",
        borderBottomColor: theme.colors.lightBlue
    })
)

const BrowsItemName = styled(Text)(
    ({ theme }) => ({
        marginRight: 10,
        /** 
        ${props => props.isFolder === true ? `
        fontWeight: "800",
        `
        :
        `
        fontWeight: "400",
        `    } */
    })
)

const BrowsItemInfo = styled(Text)(
    ({ theme }) => ({
        marginRight: 10,
    })
)

const BrowsItemIcon = styled(Icon)(
    ({ theme }) => ({
        marginRight: 10,
        color: theme.colors.primary,
        alignSelf: "center"
    })
)

const BrowsItemContextMenu = styled(MenuButton)(
    ({ theme }) => ({
        backgroundColor: "transparent !important",
        color: "transparent",
        height: 25,
        alignItems: "center",
    })
)

export default FileBrowser;
