/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import { useGetQueryString, Box, Text, useBackend } from '@raytion/sri-frontend-ui-components'
import TextButton from '../components/TextButton';
import { useAppState } from '../context/AppProvider'
import url from '../config/url'
import { useParams } from "react-router-dom";
import saveAs from 'file-saver'


const ExportButton: React.FC  = () => {
  const [appState] = useAppState();
  const {project} = useParams();
  const [backend] = useBackend()
  const getQueryString = useGetQueryString()
  // download
  const handleOnClick = () => {
    fetch(`${url}export?${getQueryString({num: 5000})}&frontend=${backend || project}`, 
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: ` Bearer ${appState.graphToken}`
        },
        method: "GET",
      })
      .then(response => {
        if(response.ok)
        {
          return response.blob().then(blob => {
            return {
              contentDisposition: response.headers.get("Content-Disposition"),
              blob: blob
            }
          })         
        }
        throw new Error(`${response.status}`);
      })
      .then(({blob, contentDisposition}) => {
        let filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
        let matches = contentDisposition ? filenameRegex.exec(contentDisposition) : [];
        let filename = matches && matches[1] ? matches[1] : 'undefined';
        saveAs(blob, filename)
      })
      .catch(function(res){ console.log(res) })
  }

  return (
    <TextButton 
      gap={2}
      icon={
        <Text as="span" css={{lineHeight: 1}}>
          <ExportIcon />
        </Text>
      }
      reverse={true}
      onClick={handleOnClick}
    >
      Export Results
    </TextButton>     
  )
}

const ExportIcon = () => (
  <Box mt="-4px">
    <svg xmlns="http://www.w3.org/2000/svg" width={20} fill="currentColor" viewBox="0 0 576 512">
      <path d="M195.515 374.828c-4.686-4.686-4.686-12.284 0-16.971l323.15-323.15-.707-.707-89.958.342c-6.627 0-12-5.373-12-12v-9.999c0-6.628 5.372-12 12-12L564 0c6.627 0 12 5.372 12 12l-.343 136c0 6.627-5.373 12-12 12h-9.999c-6.627 0-12-5.373-12-12L542 58.042l-.707-.707-323.15 323.15c-4.686 4.686-12.284 4.686-16.971 0l-5.657-5.657zm232-155.633l-8 8A12 12 0 0 0 416 235.68V464c0 8.837-7.164 16-16 16H48c-8.836 0-16-7.163-16-16V112c0-8.837 7.164-16 16-16h339.976c3.183 0 6.235-1.264 8.485-3.515l8-8c7.56-7.56 2.206-20.485-8.485-20.485H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V227.681c0-10.691-12.926-16.045-20.485-8.486z"/>
    </svg>
  </Box>
)

export default ExportButton;
