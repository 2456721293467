/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useParams, useHistory, useLocation } from "react-router-dom";
import { authContext } from "../config/msalConfig";
import { styled, Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text, Navbar, NavbarItem } from '@raytion/sri-frontend-ui-components'
import Logo from './Logo'

type SearchProps = { config: any, displayName?: string, }

const Header: React.FC <SearchProps> = ({config, displayName = 'undefined'}) => {
  let { project, container } = useParams();
  const location = useLocation();
  const history = useHistory();

  const handleChange = (target: string) => {
    if(project){
      history.push("/" + project + "/" + target);
    } else {
      history.push("/" + target);
    }
  };
  return(
    <StyledHeader>
      <Flex 
        justifyContent="space-between" 
        alignItems="center" 
        css={{margin: '0 auto', maxWidth: 1180, height: '100%'}}
        px={8}     
      >
        <Flex>
          <Logo project={project} container={container} uploadHome={location.pathname.includes("upload")} inSearch={location.pathname.includes("search")} />
            <Navbar ml={125}>
              <StyledNavbarItem
                onClick={() => handleChange("")}
                data-selected={!location.pathname.includes("search") && !location.pathname.includes("upload") ? true : null}
                mr={20}>
                    Homepage
              </StyledNavbarItem>
              { project && 
                <Flex>
                  <NavbarItem mr={20} style={{ cursor: "default" }} disabled>
                    <Text fontSize="24px" lineHeight="19px" fontWeight="50" textColor="#E0E4F2">
                      |
                    </Text>
                  </NavbarItem>
                  <StyledNavbarItem
                    onClick={() => handleChange("search")}
                    data-selected={location.pathname.includes("search") ? true : null}
                    mr={20}>
                        Search
                  </StyledNavbarItem>
                </Flex>
              }
              <NavbarItem mr={20} style={{ cursor: "default" }} disabled>
                <Text fontSize="24px" lineHeight="19px" fontWeight="50" textColor="#E0E4F2">
                  |
                </Text>
              </NavbarItem>
              <StyledNavbarItem
                data-selected={location.pathname.includes("upload") ? true : null}
                onClick={() => handleChange("upload")}>
                    Upload
              </StyledNavbarItem>
            </Navbar>
        </Flex>
        <Box>
          <Menu>
            <StyledButton >
              {displayName} 
            </StyledButton>
            <MenuList>
<StyledItem onSelect={async () => {
  try {
    await authContext.logoutRedirect();
  } catch (error) {
    console.error('Error during logout:', error);
  }
}}>
  Logout
</StyledItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </StyledHeader>
  )
}

export default Header;

const StyledHeader = styled.header(({ theme }) =>
  ({
    width: '100%',
    height: 90,
    backgroundColor: 'white'
  })
)

const StyledButton = styled(MenuButton)(({ theme }) =>
  ({
    ...theme.textStyles.text,
    color: theme.colors.text,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.colors.primary,
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: theme.colors.primary,
    },
    '&:disabled': {
      color: theme.colors.textDisabled,
      backgroundColor: 'transparent',
    },
    '&[aria-expanded="true"]': {
      backgroundColor: 'transparent',
      color: theme.colors.primary,
    },
  })
)

const StyledNavbarItem = styled(NavbarItem)(({ theme }) =>
({
  fontFamily: 'Roboto Condensed, sans-serif',
  fontWeight: 300,
  fontSize: 16,
  lineHeight: '19px',
  textTransform: 'uppercase',
  color: "#202020",
  '&:active': {
    fontWeight: "bold"
  },
  '&[data-selected="true"]': {
    fontWeight: "bold"
  }
})
)

const StyledItem = styled(MenuItem)(({ theme }) =>
  ({
    ...theme.textStyles.text,
    color: theme.colors.text,
    backgroundColor: 'transparent',
    '&[data-selected]': {
      backgroundColor: 'white',
      color: theme.colors.text,
    },
    '&:hover': {
      color: theme.colors.primary,
    },
  })
)


