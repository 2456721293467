import React from 'react';
import { Box, styled, Text, Icon, Flex } from '@raytion/sri-frontend-ui-components';
import Spinner from './Spinner';
import { useUploadState } from '../context/UploadProvider';

type UploadProgressProps = {}

const UploadProgress: React.FC<UploadProgressProps> = () => {
    const { browserContext } = useUploadState();
    
    return (
        <DropzoneBox mt={30} zIndex={200} position="relative" >
            <Flex>
                <Icon mr={2} size={20} color="#202020" className="fal fa-upload"></Icon>
                <Box width="100%">
                    <Text variant="button" textColor="#202020">Upload Files</Text>
                    <Text mt={2} fontSize={12} lineHeight="14px" textColor="#202020">
                        {browserContext.uploadFile}
                    </Text>
                    <Box>
                        <ProgressBar>
                            <div style={{
                                height: "6px",
                                backgroundColor: "#395A83",
                                width: browserContext.uploadProgress+"%",
                            }}></div>
                        </ProgressBar>
                        <Flex justifyContent="space-between">
                            <Text fontSize={12} lineHeight="14px" textColor="#202020">
                                {browserContext.uploadProgress}% finished
                            </Text>
                            <Box alignSelf="end">
                                <Spinner size={10} />
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </DropzoneBox>
    );
}

const DropzoneBox = styled(Box)(
    ({ theme }) => ({
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "25px",
        paddingRight: "25px",
        borderRadius: "2px",
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='pattern' width='3' height='3' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(219)'%3E%3Crect id='pattern-background' width='400%25' height='400%25' fill='rgba(255, 255, 255,1)'%3E%3C/rect%3E%3Cpath fill='rgba(255, 255, 255,1)' d='M-10 30h60v20h-60zM-10-10h60v20h-60'%3E%3C/path%3E%3Cpath fill='rgba(224, 228, 242,1)' d='M-10 10h60v20h-60zM-10-30h60v20h-60z'%3E%3C/path%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23pattern)' height='100%25' width='100%25'%3E%3C/rect%3E%3C/svg%3E")`,
        outline: "none",
        transition: "border .24s ease-in-out",
        maxWidth: "250px"
    })
)

const ProgressBar = styled.div(({ theme }) => ({
    width: "100%",
    backgroundColor: "#ddd",
    marginTop: "15px",
    marginBottom: "5px"
}))

export default UploadProgress;