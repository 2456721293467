/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Flex, Box, Text, Divider } from '@raytion/sri-frontend-ui-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import FileBrowser from '../components/FileBrowser';
import ContainerList from '../components/ContainerList';
import Dropzone from '../components/Dropzone';
import UploadError from '../components/UploadError';
import UploadProgress from '../components/UploadProgress';
import DeleteProgress from '../components/DeleteProgress';
import DownloadProgress from '../components/DownloadProgress';
import UploadSuccess from '../components/UploadSuccess';
import CreateFolder from '../components/CreateFolder';
import CreateFolderTextField from '../components/CreateFolderTextField';
import { useUploadState } from '../context/UploadProvider';
import { useAppState } from '../context/AppProvider';
import { useUpload } from '../hooks/useUpload';
import { Config } from '../config-types';
import ButonSecondary from '../components/ButonSecondary';

type SearchProps = { config: Config, displayName: string }


const Upload: React.FC<SearchProps> = ({ config, displayName }) => {
    const location = useLocation();
    const { browserContext, load, hasWritePermissions } = useUploadState();
    const { triggerIndex } = useUpload();
    const [appState] = useAppState();

    useEffect(() => {
        load();
    /* eslint-disable react-hooks/exhaustive-deps*/
    }, [location, appState.rolesAssignments]);
    
    return (
        <Flex flexDirection="column" css={{ minHeight: '100vh' }}>
            {(browserContext.uploadState === "UPLOADING" || browserContext.uploadState === "DELETING" || browserContext.uploadState === "DOWNLOADING") &&
                <Box
                    position="fixed"
                    top={0}
                    right={0}
                    left={0}
                    bottom={0}
                    backgroundColor="white"
                    zIndex={10}
                    css={{ opacity: 0.7 }}
                />
            }
            <Header config={config} displayName={displayName} />
            <Box flexGrow={1}>
                <Box css={{ margin: '0 auto', maxWidth: 1180 }} px={8}>
                    <Flex justifyContent="space-between">
                        <Flex mt={6} flexShrink={0} mr={6} css={{ height: 24 }}>
                            <Text variant="button" fontWeight={300} css={{ textTransform: 'uppercase' }}>
                                { browserContext.showingContainers ? "Datasources": "Directory"}
                            </Text>
                            <Breadcrumb breadCrumb={browserContext.breadCrumb} />
                        </Flex>
                        {
                            !browserContext.showingContainers && hasWritePermissions() &&
                            <ButonSecondary mt={4} css={{height: 30, width: 150}} onClick={triggerIndex}>
                                <Text variant="button" fontSize={12}>
                                    Index to search
                                </Text>
                            </ButonSecondary>
                        }
                    </Flex>
                    <Divider backgroundColor="E0E4F2" mb={4} mt={2} height={2}/>
                    <Flex>
                        {
                            !browserContext.showingContainers && hasWritePermissions() &&
                            <Box mr={30} flexGrow={1} flex={3} mt={2}>
                                    <CreateFolder />
                                    {browserContext.uploadState === "ERROR" && 
                                        <UploadError />
                                    }
                                    {browserContext.uploadState === "FINISHED" && 
                                        <UploadSuccess />
                                    }
                                    {browserContext.uploadState === "UPLOADING" && 
                                        <UploadProgress />
                                    }
                                    {browserContext.uploadState === "INIT" && 
                                        <Dropzone />
                                    }
                                    {browserContext.uploadState === "DELETING" &&
                                        <DeleteProgress />
                                    }
                                    {browserContext.uploadState === "DOWNLOADING" &&
                                        <DownloadProgress />
                                    }
                            </Box>
                        }
                        <Box flexGrow={1} flex={9}>
                            {browserContext.showTextField &&
                                <CreateFolderTextField />
                            }
                            {
                                browserContext.showingContainers &&
                                <ContainerList />
                            }
                            {
                                !browserContext.showingContainers &&
                                <FileBrowser />
                            }
                        </Box>
                    </Flex>
                </Box>
            </Box>
            <Footer />
        </Flex>
    );
}



export default Upload;
