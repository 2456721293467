import React, { useState, useEffect } from 'react';
import { styled, Icon, Box, Text } from '@raytion/sri-frontend-ui-components'
import { useAppState } from '../context/AppProvider'
import { useParams } from "react-router-dom";
import { config } from '../config/config';
import { getGraphToken } from '../config/msalConfig';

type PreviewProps = PreviewWrapperProps & { handleTabsChange: any, open: any, setBlob: any, setShowSpinner: any, showSpinner: boolean}

const Preview: React.FC<PreviewProps> = ({ url, handleTabsChange, open, setBlob, setShowSpinner, showSpinner}) => {
  const { project } = useParams();
  const [state, setState] = useAppState();
  const api = config.api
  const [thumbnail, setThumbnail] = useState<null | string>(null);
  const [startPage, setStartPage] = useState(3);
  
  const handleCLick = () => {
    handleTabsChange(0); 
    open()
    if(url && project){
      fetchPreview(api, project, state.graphToken, url)
      .then(blob => {
        setBlob(URL.createObjectURL(blob))
        setShowSpinner(false)
        setStartPage(startPage+3)
      })
    }
  }

  useEffect(() => {
    if (url && showSpinner && project) {
      fetchPreview(api, project, state.graphToken, url)
        .then(blob => {
          setBlob(URL.createObjectURL(blob))
          setShowSpinner(false)
          setStartPage(startPage + 3)
        })
    }
  /* eslint-disable react-hooks/exhaustive-deps*/
  }, [showSpinner])

  useEffect(() => {
    if (state.graphToken && project && url) {
      fetch(api + `thumbnail?frontend=${project}&uri=${encodeURIComponent(url)}&width=120&height=170`, {
        headers: { Authorization: ` Bearer ${state.graphToken}`},
        method: "GET",
      })
        .then(response => response.blob())
        .then(blob => {
          setThumbnail(URL.createObjectURL(blob))
        })
    }
  },[])

  const fetchPreview = async (api: string, project: string, graphToken: string, url: string) => {
    let preview = await fetch(api + `preview?frontend=${project}&uri=${encodeURIComponent(url)}&query=%23all&start_page=1&pages=${startPage}`,{
      headers: { Authorization: ` Bearer ${graphToken}`},
      method: "GET",
    })

    if (preview.status !== 200) {
      const newToken = await getGraphToken();
      if (setState) {
        setState(state => ({ ...state, graphToken: newToken }));
      }
      preview = await fetch(api + `preview?frontend=${project}&uri=${encodeURIComponent(url)}&query=%23all&start_page=1&pages=${startPage}`,{
        headers: { Authorization: ` Bearer ${graphToken}`},
        method: "GET",
      });
    }

    return preview.blob();
  }

  return (
    <PreviewWrapper className="wrapper" onClick={handleCLick}>
      {thumbnail ? 
        <PopoverImg src={thumbnail}></PopoverImg>:
        <PopoverDummy />
      }
      <Box>
        <Icon size={20} ml={2} className="fal fa-expand" />
        <Text variant="meta">Preview</Text>
      </Box>
    </PreviewWrapper>
  )
}

type PreviewWrapperProps = {
  url?: string
}

const PreviewWrapper = styled.div<PreviewWrapperProps>(()=>({
  display: 'flex',
  position: "relative",
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  width: 120,
  height: 170,
  cursor: "pointer"
}))

const PopoverDummy = styled(Box)(() =>
({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: '#ECEFF7',
  zIndex: -1,
  '.wrapper:hover &': {
    opacity: 0.2,
  },
}))

const PopoverImg = styled.img(() =>
({
  position: "absolute",
  width: "120px",
  height: "170px",
  backgroundColor: '#ECEFF7',
  zIndex: -1,
  '.wrapper:hover &': {
    opacity: 0.2,
  },
}))

export default Preview