/** @jsx jsx */
/* eslint-disable @typescript-eslint/no-unused-vars*/

import { jsx } from '@emotion/core'
import React from 'react';
import { useUploadState } from '../context/UploadProvider';
import { Flex, Box, Text, MenuButton, Icon, styled } from '@raytion/sri-frontend-ui-components';

type ContainerListProps = {}

const ContainerList: React.FC<ContainerListProps> = () => {
    const { browserContext, handleNavigation } = useUploadState();


    return (
        <Box>
            {browserContext.contents.length !== 0 &&
                <Box>
                    <Flex justifyContent="space-between">
                        <div></div>
                        <Text variant="button" fontWeight={300} css={{ textTransform: 'uppercase' }}>
                            Choose a datasource
                        </Text>
                        <div></div>
                    </Flex>
                    <ContainerBox mt={3} mb={10} ml={260} mr={260}>
                        {browserContext.contents.map((container: any, i: number) => {
                            return (
                                <FileBrowsRow justifyContent="space-between" key={container.value.name + i} style={{ cursor: "pointer" }} onClick={(e) => {
                                    handleNavigation(container.value.name)
                                }}>
                                    <div></div>
                                    <BrowsItemName>
                                        <Text fontWeight={600}>{container.value.name}</Text>
                                    </BrowsItemName>
                                    <div></div>
                                </FileBrowsRow>
                            );
                        })}
                    </ContainerBox>
                </Box>
            }
            {browserContext.contents.length === 0 &&
                <Text mt={3} textAlign="center" fontWeight={600}>{"Loading..."}</Text>
            }
        </Box>
    );
}

const ContainerBox = styled(Box)(
    ({ theme }) => ({
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.colors.lightBlue
    })
)


const FileBrowsRow = styled(Flex)(
    ({ theme }) => ({
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 5,
        paddingLeft: 20,
        alignItems: "center",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: theme.colors.lightBlue,
        '&:hover': {
            backgroundColor: theme.colors.lightBlueHover,
            '& .icon-hover': {
                color: theme.colors.primary,
            }
        },
    })
)

const FileBrowsHead = styled(Flex)(
    ({ theme }) => ({
        paddingBottom: 15,
        paddingRight: 5,
        paddingLeft: 20,
        alignItems: "center",
        borderBottomWidth: "2px",
        borderBottomStyle: "solid",
        borderBottomColor: theme.colors.lightBlue
    })
)

const BrowsItemName = styled(Text)(
    ({ theme }) => ({
        marginRight: 10,
        /** 
        ${props => props.isFolder === true ? `
        fontWeight: "800",
        `
        :
        `
        fontWeight: "400",
        `    } */
    })
)

const BrowsItemInfo = styled(Text)(
    ({ theme }) => ({
        marginRight: 10,
    })
)

const BrowsItemIcon = styled(Icon)(
    ({ theme }) => ({
        marginRight: 10,
        color: theme.colors.primary,
        alignSelf: "center"
    })
)

const BrowsItemContextMenu = styled(MenuButton)(
    ({ theme }) => ({
        backgroundColor: "transparent !important",
        color: "transparent",
        height: 25,
        alignItems: "center",
    })
)

export default ContainerList;
