/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import { maxWidth, MaxWidthProps } from 'styled-system'
import { Text, Box, Icon } from '@raytion/sri-frontend-ui-components'
import { DialogOverlay as Overlay, DialogContent as Content, DialogProps as DefaultProps } from "@reach/dialog";
import { styled} from '@raytion/sri-frontend-ui-components';
import IconButton from './IconButton';

type DialogProps = DefaultProps & MaxWidthProps & {
  title: string
}

export const Dialog: React.FC <DialogProps> = ({title, maxWidth, isOpen, onDismiss, children}) => {
  return(
    <DialogOverlay isOpen={isOpen} onDismiss={onDismiss}>
      <DialogContent maxWidth={maxWidth}>
        <Header>
          <Text 
            fontSize={16} 
            fontWeight={300} 
            css={{
              textTransform: 'uppercase', 
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {title}
          </Text>
          <IconButton onClick={onDismiss} >
            <Icon size={20} className="fal fa-times" />
          </IconButton>
        </Header>
        <Box p={6}>
          {children}
        </Box>
      </DialogContent>
    </DialogOverlay>
  )
}

export default Dialog


const Header = styled.div(
  ({theme}) => ({
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.space[6],
    paddingRight: theme.space[4],
    backgroundColor: theme.colors.lightBlue
  })
)


const DialogOverlay = styled(Overlay)(
  ({theme}) => ({
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0,0,0,.33)',
    zIndex: 20,
  })
)

const DialogContent = styled(Content)<MaxWidthProps>(
  ({theme}) => ({
    minWidth: 500,
    minHeight: 500,
    background: '#fff',
    outline: 'none',
  }),
  maxWidth
)