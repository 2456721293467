import React from 'react';
import { useUploadState } from '../context/UploadProvider';
import { Box, styled, Text, Icon } from '@raytion/sri-frontend-ui-components';

type CreateFolderProps = {}

const CreateFolder: React.FC<CreateFolderProps> = () => {
    const { browserContext, setBrowserContext } = useUploadState();

    return (
        <Box>
            {!browserContext.showTextField &&
                <CreateFolderButton onClick={() => setBrowserContext({ ...browserContext, showTextField: true })}>
                    <Text variant="button">
                        <Icon mr={2} size={20} color="#202020" className="fal fa-folder"></Icon>
                        Create new Folder
                    </Text>
                </CreateFolderButton>
            }
            {browserContext.showTextField &&
                <CreateFolderButton onClick={() => setBrowserContext({ ...browserContext, showTextField: false })}>
                    <Text variant="button">
                        <Icon mr={2} size={20} color="#202020" className="fal fa-times-circle"></Icon>
                        Cancel
                    </Text>
                </CreateFolderButton>
            }
        </Box>
    );
}

const CreateFolderButton = styled(Box)(
    ({ theme }) => ({
        alignItems: "center",
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "25px",
        paddingRight: "57px",
        borderRadius: "2px",
        cursor: "pointer",
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='pattern' width='3' height='3' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(219)'%3E%3Crect id='pattern-background' width='400%25' height='400%25' fill='rgba(255, 255, 255,1)'%3E%3C/rect%3E%3Cpath fill='rgba(255, 255, 255,1)' d='M-10 30h60v20h-60zM-10-10h60v20h-60'%3E%3C/path%3E%3Cpath fill='rgba(224, 228, 242,1)' d='M-10 10h60v20h-60zM-10-30h60v20h-60z'%3E%3C/path%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23pattern)' height='100%25' width='100%25'%3E%3C/rect%3E%3C/svg%3E")`,
        outline: "none",
        transition: "border .24s ease-in-out",
        maxWidth: "250px"
    })
)

export default CreateFolder;