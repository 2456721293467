import React, { createContext, useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useAppState } from './AppProvider'
import url from '../config/url'

type TagListContextType = [string[], () => void]
const TagList = createContext<TagListContextType>([[], ()=>{}])

const TagListProvider: React.FC = ({children}) => {
  let { project } = useParams();
  const [ appState ] = useAppState();
  const [state, setState] = useState([])

  useEffect(()=>{
    fetchTagList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchTagList = () => {
    fetch(`${url}tag?frontend=${project}`, 
      {
        headers: {
          Authorization: ` Bearer ${appState.graphToken}`
        },
      })
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        setState(myJson.project_tags)
      })
      .catch(function(res){ 
        console.log(res) 
      });
  }

  return (
    <TagList.Provider value={[state, fetchTagList]}>
      {children}
    </TagList.Provider>
  )
}

function useTagList() {
  const context = React.useContext(TagList)
  return context
}

export {TagListProvider, useTagList}
