import React from 'react'
import DayPicker from 'react-day-picker';
import { DateNavigatorInput } from './DateNavigatorInput'
import { DateNavigator as BaseDateNavigator, DateNavigatorProps,Box, Flex, Text } from '@raytion/sri-frontend-ui-components';
import ButonSecondary from './ButonSecondary';
// import ExcludeToggle from './ExcludeToggle'

const DateNavigator: React.FC<DateNavigatorProps> = ({title, ...props}) => {
  return(
    <BaseDateNavigator {...props}>
      {({
        getDayPickerProps, 
        getEndInputProps, 
        getStartInputProps, 
        wrapperRef, 
        activeInput, 
        // isExclude,
        // toggleExclude,
        handleSubmit 
      }) => (
        <Box>
          <Flex mb={1} justifyContent="space-between" alignItems="center">
            <Text variant="text" pb={1} fontWeight={700}>
              {title}
            </Text>
            {/* <ExcludeToggle 
              checked={isExclude} 
              onChange={toggleExclude} 
            /> */}
          </Flex>
          <Box ref={wrapperRef}>
            <Flex mb={4}>
              <DateNavigatorInput {...getStartInputProps()} mr={2} />
              <DateNavigatorInput {...getEndInputProps()} />
            </Flex>
            {activeInput && (
              <Box 
                boxShadow="popover" 
                width="100%" 
                zIndex={100} 
                bg="white" 
                position="absolute"
                px={5}
                pt={4}
              >
                <DayPicker 
                  {...getDayPickerProps()} 
                />
              </Box>
            )}
          </Box>
          <ButonSecondary onClick={handleSubmit} fullWidht>
            Apply
          </ButonSecondary>
        </Box>
      )}
    </BaseDateNavigator>
  )
}

export default DateNavigator