import React from 'react';
import { Box, styled, Text, Icon, Flex } from '@raytion/sri-frontend-ui-components';
import { useUploadState } from '../context/UploadProvider';

type UploadSuccessProps = {}

const UploadSuccess: React.FC<UploadSuccessProps> = () => {
    const { browserContext, setBrowserContext } = useUploadState();

    const handleOnClick = () => {
        setBrowserContext({ ...browserContext, uploadState: "INIT", uploadProgress: 0, uploadFile: "" }); 
    }

    return (
        <DropzoneBox mt={30}>
            <Flex>
                <Icon mr={2} size={20} color="#46B450" className="fal fa-check-circle"></Icon>
                <Box width="100%" pr={2}>
                    <Text variant="button" textColor="#202020">Upload Files</Text>
                    <Text mt={2} fontSize={12} lineHeight="14px" textColor="#202020"
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}>                       
                        {browserContext.uploadFile}
                    </Text>
                    <Box>
                        <ProgressBar>
                            <Bar></Bar>
                        </ProgressBar>
                        <Text fontSize={12} lineHeight="14px" textColor="#46B450">
                            100% finished
                        </Text>
                        <Text style={{
                            fontWeight: "bold",
                            cursor: "pointer",
                            textDecoration: "underline"
                        }} fontSize={12} mt={1} lineHeight="14px" textColor="#202020" onClick={handleOnClick}>
                            Upload another file
                        </Text>
                    </Box>
                </Box>
            </Flex>
        </DropzoneBox>
    );
}

const DropzoneBox = styled(Box)(
    ({ theme }) => ({
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "25px",
        paddingRight: "25px",
        borderRadius: "2px",
        backgroundColor: "#E4F4E5",
        outline: "none",
        transition: "border .24s ease-in-out",
        maxWidth: "250px"
    })
)

const ProgressBar = styled.div(({ theme }) => ({
    width: "100%",
    backgroundColor: "#ddd",
    marginTop: "15px",
    marginBottom: "5px"
}))

const Bar = styled.div(({ theme }) => ({
    width: "100%",
    height: "6px",
    backgroundColor: "#46B450"
}))

export default UploadSuccess;