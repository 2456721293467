/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import { ThemeProvider, Box, Flex } from '@raytion/sri-frontend-ui-components'
import Footer from './components/Footer';
import Header from './components/Header';
import { theme } from './config/theme';
import background from './assets/homepage-bg.png'

import { config } from './config/config';

const App: React.FC = () => {  
  return (
    <ThemeProvider theme={theme}>
      <Flex flexDirection="column" css={{height: '100vh'}}>
        <Header config={config} displayName='Unauthenticated' />
        <Box 
          bg='brand'
          p={4}
          flexGrow={1}
          css={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            position: 'relative',
            backgroundImage: `url(${background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}>
        </Box>
        <Footer />
      </Flex>
    </ThemeProvider>
  );
}

export default App;
