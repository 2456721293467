import React from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import { ThemeProvider, SearchProvider, TranslationProvider, OnSearch } from '@raytion/sri-frontend-ui-components'
import Search from './pages/Search';
import NotFound from './pages/NotFound';
import Homepage from './pages/Homepage';
import Download from './pages/Download';
import Upload from './pages/Upload';

import { config } from './config/config';
import { theme } from './config/theme';
import { languages } from './config/languages';
import { getGraphToken } from './config/msalConfig';
import { useAppState } from './context/AppProvider'
import { UploadProvider } from './context/UploadProvider'

const App = () => {
  const params = useLocation()
  const [state, setState] = useAppState();

  const onSearch: OnSearch = ({api, searchString, setReponse}) => {
    const project = params.pathname.split("/")[1];
    let backend = searchString.split("&").find(param => param.includes("backend"))
    if(backend){
      backend = backend.split("=")[1]
    }
    if (state.graphToken && project){
      const url = api + `search?${searchString}&frontend=${backend || project}`;
      const headers = { Authorization: ` Bearer ${state.graphToken}` }; 
      fetch(url, { headers })
      .then((response) => {
        if(response.ok) {    
          response.json().then((responseJson) => {    
            if(setState) setState(state => ({...state, error: ''}));      
            setReponse(responseJson);
          });
        }
        else {
          getGraphToken().then((response: any) => {
            if (setState) {
              setState(state => ({ ...state, graphToken: response }));
              const headers = { Authorization: ` Bearer ${response}` };
              fetch(url, { headers }).then((response) => {
                if (response.ok) {
                  response.json().then((responseJson) => {
                    if (setState) setState(state => ({ ...state, error: '' }));
                    setReponse(responseJson);
                  });
                } else {
                  response.json().then((responseJson) => {
                    if (setState) setState(state => ({ ...state, error: responseJson.message, authorized: response.status.toString() }));
                    setReponse({});
                  });
                }
              })
            }
          });

        }
      })
      .catch((error) => {
        setReponse({});
        if(setState) setState(state => ({...state, error: error.toString()}));      
      });
    }
  };
  
  return (
    <SearchProvider isSearchActive={false} onSearch={onSearch} api={config.api} trackUrlState={true}>
      <TranslationProvider translations={languages}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/download" >
              <Download config={config} displayName={state.responseMe.displayName}/>
            </Route>
            <Route path="/:project/upload/:container" >
              <UploadProvider>
                <Upload config={config} displayName={state.responseMe.displayName} />
              </UploadProvider>
            </Route>
            <Route path="/:project/upload" >
              <UploadProvider>
                <Upload config={config} displayName={state.responseMe.displayName} />
              </UploadProvider>
            </Route>
            <Route path="/upload/:container" >
              <UploadProvider>
                <Upload config={config} displayName={state.responseMe.displayName} />
              </UploadProvider>
            </Route>
            <Route path="/upload" >
              <UploadProvider>
                <Upload config={config} displayName={state.responseMe.displayName} />
              </UploadProvider>
            </Route>
            <Route path="/:project/search" >
              <Search config={config} displayName={state.responseMe.displayName} />
            </Route>
            <Route path="/:project" >
              <Homepage config={config} displayName={state.responseMe.displayName} />
            </Route>
            <Route path="/" >
              <Homepage config={config} displayName={state.responseMe.displayName} />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch> 
        </ThemeProvider>
      </TranslationProvider>
    </SearchProvider>
  );
}


export default App;
