/* eslint-disable array-callback-return*/

import React, { createContext, useState, useEffect } from 'react'
import { msalApiFetch, acquireTokenSilently, getManagementToken } from "../config/msalConfig";

type State = {
  responseJSON: string;
  responseMe: any;
  responseGroups: string;
  responseMeMemberOf: string;
  rolesAssignments: {
    permissionAllContainers: {
      read: boolean,
      write: boolean
    },
    permissionContainer: {
      containerName: string,
      read: boolean,
      write: boolean
    }[]
  };
  project: string;
  authorized: string;
  responseCustomAPI: string;
  userEmail: string;
  graphToken: string;
  storageToken: string;
  error: string,
}

const defaultState: State = {
  responseJSON: '',
  responseMe: '' as any,
  responseGroups: '',
  responseMeMemberOf: '',
  project: '',
  authorized: '',
  responseCustomAPI: '',
  userEmail: '',
  graphToken: '',
  storageToken: '',
  error: '',
  rolesAssignments: {
    permissionAllContainers: {
      read: false,
      write: false
    },
    permissionContainer: []
  }
}

type AppContextType = [State,React.Dispatch<React.SetStateAction<State>> | null]

const AppContext = createContext<AppContextType>([defaultState, null])

const AppProvider: React.FC = (props) => {
  const subscriptionID = "579c19dc-f79b-4a36-b1c9-aef9129941e9";
  const stateContext = useState(defaultState)
  const setState = stateContext[1];

useEffect(() => {

  acquireTokenSilently().then((loginResponse: any) => {
    setState((state) => ({
      ...state,
      userEmail: loginResponse.account.username,
      displayName: loginResponse.account.name,
      graphToken: loginResponse.accessToken,
    })); //setState close here

      msalApiFetch(fetch, 'https://graph.microsoft.com/v1.0/me', loginResponse.accessToken, {}).then((response: any) => {
        response.json().then((responseJson: any) => {
          const userId = responseJson.id;
          try {
            getManagementToken().then((managementToken: any) => {
              setState((state) => ({ ...state, managementToken: managementToken }));
              try {
                getMgmtResponse('https://management.azure.com/subscriptions/' + subscriptionID + '/providers/Microsoft.Authorization/roleAssignments?api-version=2015-07-01&$filter=assignedTo(\'{' + userId + '}\')', 'storageAccess', managementToken);
              } catch (error) {
                console.error('Error getting management response:', error);
              }
            }); 
          } catch (error) {
            console.error('Error getting Management token:', error);
          }
          setState((state) => ({ ...state, responseMe: responseJson }));
        });
      }).catch((error: any) => {
        console.error('Error fetching graph data:', error);
      });

  }).catch((error: any) => {
    console.error('Error during login:', error);
  });
// eslint-disable-next-line
}, []);

  const getMgmtResponse = (url: string, eventKey: string, token: string) => {
    const graphRequest = url;
    const headers = new Headers();
    msalApiFetch(fetch, graphRequest, token, {
      method: "GET",
      headers: headers
    })
      .then((response: any) => {
        response.json()
      .then((responseJson: any) => {
        setContainerRoleAssignments(responseJson);
        setState(state => ({...state, [eventKey]: responseJson}));
      });
      })
      .catch((error: any) => {
        console.error(error);
      })
  }

  const setContainerRoleAssignments = (roles: any)  => {
    const storageAccountScope = "/subscriptions/579c19dc-f79b-4a36-b1c9-aef9129941e9/resourceGroups/OppiData/providers/Microsoft.Storage/storageAccounts/oppidataraw";
    const storageBlobDataContributor = "/subscriptions/579c19dc-f79b-4a36-b1c9-aef9129941e9/providers/Microsoft.Authorization/roleDefinitions/ba92f5b4-2d11-453d-a403-e96b0029c9fe";
    const storageBlobDataOwner = "/subscriptions/579c19dc-f79b-4a36-b1c9-aef9129941e9/providers/Microsoft.Authorization/roleDefinitions/b7e6dc6d-f1e8-4753-8033-0f276bb0955b";
    const storageBlobDataReader = "/subscriptions/579c19dc-f79b-4a36-b1c9-aef9129941e9/providers/Microsoft.Authorization/roleDefinitions/2a2b9908-6ea1-4ae2-8e65-a410df84e7d1";

    if (typeof roles == "string"){
      alert("Browser extension blocks Azure AD authentication. Please disable your Browser extensions")
      console.error("Browser extension blocks Azure AD authentication. Please disable your Browser extensions")
      return;
    }

    roles.value.map((role: any) => {
      const scope = role.properties.scope
      const roleDefinitionId = role.properties.roleDefinitionId;
      const isStorageAccountScope = scope === storageAccountScope
      if(isStorageAccountScope){
        switch (roleDefinitionId) {
          case storageBlobDataOwner:
          case storageBlobDataContributor:
            setState(state => ({ ...state, rolesAssignments: {
              permissionAllContainers: {
                read: true,
                write: true
              },
              permissionContainer: state.rolesAssignments.permissionContainer
            }
            }));
            return;
          case storageBlobDataReader:
            setState(state => ({ ...state, rolesAssignments: {
              permissionAllContainers: {
                read: true,
                write: false
              },
              permissionContainer: state.rolesAssignments.permissionContainer
            }}));
            return;
          default:
            return;
        }
      }

      const containerScope = storageAccountScope + "/blobServices/default/containers/"
      if(scope.includes(containerScope)){
        const containerName: string = scope.split(containerScope).filter((res:any) => res)[0];
        switch (roleDefinitionId) {
          case storageBlobDataContributor:
          case storageBlobDataOwner:
            setState(state => ({
              ...state, rolesAssignments: {
                permissionAllContainers: state.rolesAssignments.permissionAllContainers,
                permissionContainer: [...state.rolesAssignments.permissionContainer, { containerName: containerName, read: true, write: true }]
              }
            }));
            return;
          case storageBlobDataReader:
            setState(state => ({
              ...state, rolesAssignments: {
                permissionAllContainers: state.rolesAssignments.permissionAllContainers,
                permissionContainer: [...state.rolesAssignments.permissionContainer, { containerName: containerName, read: true, write: false }]
            }}));
            return;
          default:
            return;
        }
      }

    })
  }

  return (
    <AppContext.Provider value={stateContext} {...props} />
  )
}

function useAppState() {
  const context = React.useContext(AppContext)
  return context
}

export {AppProvider, useAppState}