import { PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '5c793708-efb1-4fca-b929-54fa81fa37fc',
    authority: 'https://login.microsoftonline.com/8b56c3b0-5307-41b3-8493-533ee7b64e77',
    tenantId: '8b56c3b0-5307-41b3-8493-533ee7b64e77',
    redirectUri: process.env.NODE_ENV !== 'production' ? 'http://localhost:3000/' : 'https://www.oppidata.eu/',
    postLogoutRedirectUri: process.env.NODE_ENV !== 'production' ? 'http://localhost:3000/' : 'https://www.oppidata.eu/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, // "sessionStorage"
    storeAuthStateInCookie: false,
  },
  endpoints: {
    api: 'https://graph.microsoft.com',
    management: 'https://management.azure.com',
  },
};

export const authContext = new PublicClientApplication(msalConfig);

export const acquireTokenSilently = async () => {
  try {
    const accounts = authContext.getAllAccounts();
    if (accounts.length > 0) {
      const account = authContext.getAccountByHomeId(accounts[0].homeAccountId);
      if (account) {
        const response = await authContext.acquireTokenSilent({
          scopes: ['openid', 'profile', 'user.read', 'User.Read'],
          account: account,
        });
        return response;
      }
      throw new Error('Account not found.');
    }
    return false;
  } catch (error) {
    console.error('Error acquiring token silently:', error);
  }
};

export const msalApiFetch = async (fetch, url, token, options) => {
  const headers = new Headers(options.headers);
  headers.append('Authorization', `Bearer ${token}`);
  const fetchOptions = {
    ...options,
    headers: headers,
  };
  return fetch(url, { ...fetchOptions });
};

const managementScopes = ['https://management.azure.com/.default'];

export async function getManagementToken() {
  try {
    const tokenResponse = await authContext.acquireTokenSilent({
      scopes: managementScopes,
    });
    const managementToken = tokenResponse.accessToken;
    return managementToken;
  } catch (error) {
    console.error('Error getting Management token:', error);
  }
}

const graphScopes = ['User.Read']; // Scopes for Microsoft Graph API

export async function getGraphToken() {
  try {
    const tokenResponse = await authContext.acquireTokenSilent({
      scopes: graphScopes,
    });
    const graphToken = tokenResponse.accessToken;
    return graphToken;
  } catch (error) {
    console.error('Error getting Graph token:', error);
  }
}