/** @jsx jsx */
/* eslint-disable eqeqeq*/

import { jsx } from '@emotion/core'
import React, {useState} from 'react';
import { useUploadState } from '../context/UploadProvider';
import { useUpload } from '../hooks/useUpload';
import { Flex, Text, Icon, styled } from '@raytion/sri-frontend-ui-components';

type CreateFolderTextFieldProps = {}

const CreateFolderTextField: React.FC<CreateFolderTextFieldProps> = () => {
    const { browserContext, setBrowserContext, load } = useUploadState();
    const { createFolder } = useUpload();
    const [ref, setRef] = useState<any>()

    if (ref) {
        ref.focus();
    }

    return (
        <CreateFolderBox>
            <Flex mr={25}>
                <Icon mr={2} size={20} mt="2px" color="#202020" className="fal fa-folder-plus"></Icon>
                <Text variant="button" mt={1} textColor="#202020">Name Folder</Text>
            </Flex>
                <input type="text" style={{ 
                        backgroundColor: "#E0E4F2", 
                        width:"80%", 
                        height:"30px", 
                        outline:"none", 
                        border: "none",
                        fontFamily: 'Roboto Condensed, sans-serif',
                        fontSize: 15,
                        paddingLeft: "5px"
                    }}
                    ref={(input) => setRef(input)}
                    onKeyUp={(e: any) => {
                        e.which = e.which || e.keyCode;
                        if (e.which == 13) {
                            createFolder(e.target.value, browserContext.breadCrumb, browserContext.dummyFileName, load)
                        }
                        if (e.which == 27) {
                            setBrowserContext({ ...browserContext, showTextField: false });
                        }
                    }}
                    onBlur={(e: any) => {
                        if (!e.target.value) {
                            setBrowserContext({ ...browserContext, showTextField: false });
                        } else {
                            createFolder(e.target.value, browserContext.breadCrumb, browserContext.dummyFileName, load)
                        }
                    }}
                />
        </CreateFolderBox>
    );
}

const CreateFolderBox = styled(Flex)(
    ({ theme }) => ({
        borderRadius: "2px",
        marginTop: "10px",
        marginBottom: "30px",
        paddingTop: "11px",
        paddingBottom: "11px",
        paddingLeft: "25px",
        paddingRight: "25px",
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='pattern' width='3' height='3' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(219)'%3E%3Crect id='pattern-background' width='400%25' height='400%25' fill='rgba(255, 255, 255,1)'%3E%3C/rect%3E%3Cpath fill='rgba(255, 255, 255,1)' d='M-10 30h60v20h-60zM-10-10h60v20h-60'%3E%3C/path%3E%3Cpath fill='rgba(224, 228, 242,1)' d='M-10 10h60v20h-60zM-10-30h60v20h-60z'%3E%3C/path%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23pattern)' height='100%25' width='100%25'%3E%3C/rect%3E%3C/svg%3E")`,
        outline: "none",
        transition: "border .24s ease-in-out",
    })
)


export default CreateFolderTextField;
