import { Theme } from '@raytion/sri-frontend-ui-components'

export const theme: Theme = {
  breakpoints: ['768px', '1024px'],
  mediaQueries: {
    medium: `@media screen and (min-width: 768px)`,
    large: `@media screen and (min-width: 1024px)`,
  },
  colors: {
    lightBlue: '#E0E4F2',
    brand: '#202020',
    brandDarken: '#202020',
    brandLighten: '#202020',
    brandContrast: 'white',
    primary: '#395A83',
    primaryDarken: '#395A83',
    primaryLighten: '#395A83',
    primaryContrast: 'white',
    red: '#C60604',
    redDarken: '#C60604',
    redLighten: '#C60604',
    redContrast: 'white',
    yellow: '#fbb216',
    yellowDarken: '#eaa400',
    yellowLighten: '#ffc12c',
    yellowContrast: 'rgba(0, 0, 0, 0.8)',
    grey: '#E0E4F2',
    greyDarken: '#E0E4F2',
    greyLighten: '#E0E4F2',
    greyContrast: '#395A83',
    neutral: [
      '#fafbfc',
      '#f3f5f8',
      '#eaeef2',
      '#d6dee6',
      '#b3c3d2',
      '#657481',
      '#282f35',
      '#1b2025',
      '#0e1114'
    ],
    text: '#212121',
    textprimary: '#212121b3',
    textLight: '#21212180',
    textDisabled: '#21212166',
    surface: '#FFFFFF',
    background: '#FFFFFF',
    header: '#00506A',
    sidebar: '#FFFFFF',
    result: '#FFFFFF',
    card: '#FFFFFF',
    resultCollapse: '#fafbfc',
    // new theme
    textSecondary: '#212121b3',
    textSubtl: '#21212180',
    surfaceDarken: '#F9F9FC',
    lightBlueHover: '#f5f6fb',
    primaryHover: '#233a52',
    redHover: '#8a0403',
  },
  radii: {
    controllComponent: '0',
    buttonComponent: '0',
    smallComponent: '8px',
    mediumComponent: '8px',
    cardComponent: '0',
    card: '0',
    searchboxComponent: '0',
    // new
    small: '4px',
    medium: '6px',
    large: '8px',
    button: '20px',
    searchbox: '0'
  },
  shadows: {
    card: 'rgba(0, 0, 0, 0.05) 0px 0.5px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px',
    resultBox: 'rgba(0, 0, 0, 0.05) 0px 0.5px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px',
    resultBoxHover: 'rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.05) 0px 2px 6px, rgba(0, 0, 0, 0.05) 0px 10px 20px',
    searchBox: 'rgba(0, 0, 0, 0.05) 0px 0.5px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px',
    searchBoxHover: 'rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.05) 0px 2px 6px, rgba(0, 0, 0, 0.05) 0px 10px 20px',
    sidebar: 'rgba(0, 0, 0, 0.05) 0px 0.5px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px',
    //new 
    popover: '0 6px 12px rgba(0, 0, 0, .08)',
    cardExpand: 'rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.05) 0px 2px 6px, rgba(0, 0, 0, 0.05) 0px 10px 20px',
    searchbox: 'rgba(0, 0, 0, 0.05) 0px 0.5px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px',
  },
  space: [
    0,
    5,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    45,
    112,
    128
  ],
  fonts: {
    normal: 'Roboto Condensed, sans-serif',
  },
  fontWeights: {
    regular: 400,
    semibold: 600
  },
  textStyles: {
    h1: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 700,
      fontSize: 59,
      lineHeight: '71px',
    },
    h2: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 700,
      fontSize: 39,
      lineHeight: '55px',
    },
    h3: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 700,
      fontSize: 30,
      lineHeight: '30px',
    },
    h4: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 700,
      fontSize: 21,
      lineHeight: '24px',
    },
    h5: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 700,
      fontSize: 17,
      lineHeight: '22px',
    },
    h6: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 700,
      fontSize: 17,
    }, 
    text: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '25px',
    },
    button: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '19px',
      textTransform: 'uppercase'
    },
    meta: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '14px',
    },
    // old
    label: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 400,
      fontSize: 14
    },
    labelSemibold: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 600,
      fontSize: 14
    },
    textSmall: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 400,
      fontSize: 12
    },
    metaSemibold: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 600,
      fontSize: 12
    },
    textHighlight: {

    },
    code: {
      fontFamily: 'monospace',
      fontWeight: 400,
      fontSize: 14,
      padding: '12px',
      backgroundColor: '#f3f5f8'
    }
  },
  styles: {}
}
