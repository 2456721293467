import React, { createContext, useState } from 'react'
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useUpload } from '../hooks/useUpload';
import { useAppState } from '../context/AppProvider';


type uploadStateType = "INIT" | "UPLOADING" | "ERROR" | "FINISHED" | "DELETING" | "DOWNLOADING";

type BrowserContext = {
    contents: any,
    showingContainers: boolean,
    breadCrumb: string,
    dummyFileName: string,
    showTextField: boolean,
    uploadState: uploadStateType,
    uploadFile: string,
    uploadProgress: number
}

type ProgressObject = {
    currState: uploadStateType,
    fileAmount: number,
    successful: number,
    percentage: number,
    error: boolean
}

const initialBrowserContext: BrowserContext = {
    contents: [],
    showingContainers: true,
    breadCrumb: "Root",
    dummyFileName: "toBeDeleted",
    showTextField: false,
    uploadState: "INIT",
    uploadFile: "",
    uploadProgress: 0
}

type UploadContextType = {
    browserContext: BrowserContext,
    setBrowserContext: React.Dispatch<React.SetStateAction<BrowserContext>> | (() => void),
    generateBreadCrumb: (target: string) => string,
    generateRelativePath: () => string,
    load: () => void,
    handleNavigation: (target: string) => void,
    hasReadPermissions: () => boolean,
    hasWritePermissions: () => boolean,
    upload: (acceptedFiles: File[]) => void,
    deleteOperation: (files: any, isFolder: boolean) => void,
    download: (files: any, filenames: any, isFolder: boolean) => void
}

const UploadContext = createContext<UploadContextType>({
    browserContext: initialBrowserContext,
    setBrowserContext: () => {},
    generateBreadCrumb: () => "null",
    generateRelativePath: () => "null",
    load: () => {},
    handleNavigation: () => {},
    hasReadPermissions: () => false,
    hasWritePermissions: () => false,
    upload: () => {},
    deleteOperation: () => {},
    download: () => {}
})

const UploadProvider: React.FC = (props) => {
    const { container, project } = useParams();
    const [browserContext, setBrowserContext] = useState(initialBrowserContext);
    const { getContainers, getBlobs, uploadBlobs, deleteFolder, deleteBlob, downloadFolder, downloadFile } = useUpload();
    const history = useHistory();
    const location = useLocation();
    const [appState] = useAppState();

    const progressObject: ProgressObject = {
        currState: "INIT",
        fileAmount : 0,
        successful: 0,
        percentage: 0,
        error: false
    }

    const generateBreadCrumb = (target: string) => {
        if (!container) {
            return "root/" + target;
        }
        return "root/" + container + "/" + target;
    }

    const load = () => {
        if (!container) {
            if (appState.rolesAssignments.permissionAllContainers.read){
                getContainers().then((res: any) => {
                    setBrowserContext({ ...browserContext, contents: res, showingContainers: container ? false : true, breadCrumb: "root", showTextField: false, uploadState: progressObject.currState })
                });
            } else {
                const permittedContainer: any = listPermittedContainersOnly();
                setBrowserContext({ ...browserContext, contents: permittedContainer, showingContainers: container ? false : true, breadCrumb: "root", showTextField: false, uploadState: progressObject.currState })
            }
        } else {
            const prefix = generateRelativePath();
            getBlobs(container, prefix).then((res: any)=> {
                setBrowserContext({ ...browserContext, contents: res, showingContainers: container ? false : true, breadCrumb: generateBreadCrumb(prefix), showTextField: false, uploadState: progressObject.currState })
            })
        }
    }

    const upload = (acceptedFiles: File[]) => {
        setBrowserContext({ ...browserContext, uploadState: "UPLOADING" });
        progressObject.fileAmount = acceptedFiles.length; 
        uploadBlobs(acceptedFiles, generateRelativePath, load, setProgress, setError);
    }

    const download = (files: any, filenames: any, isFolder: boolean) => {
        setBrowserContext({ ...browserContext, uploadState: "DOWNLOADING" });
        isFolder ? downloadFolder(files, filenames, browserContext.dummyFileName, load, setError) : downloadFile(files, filenames, load, setError)
    }

    const deleteOperation = (files: any, isFolder: boolean) => {
        setBrowserContext({ ...browserContext, uploadState: "DELETING" });
        isFolder ? deleteFolder(files, load, setError) : deleteBlob(files, load, setError)
    }

    const setProgress = (filename: string) => {
        progressObject.successful += 1;
        progressObject.percentage = (progressObject.successful * 100) / progressObject.fileAmount;
        if (progressObject.percentage === 100 && container) {
            const prefix = generateRelativePath();
            getBlobs(container, prefix).then((res: any) => {
                progressObject.currState = progressObject.currState === "ERROR" ? progressObject.currState : "FINISHED";
                setBrowserContext({ ...browserContext, contents: res, showingContainers: container ? false : true, breadCrumb: generateBreadCrumb(prefix), showTextField: false, uploadState: progressObject.currState, uploadProgress: progressObject.percentage, uploadFile: filename })
            })
        } else {
            setBrowserContext((state) => ({ ...state, uploadProgress: Math.floor(progressObject.percentage), uploadFile: filename }))
        }
    }

    const setError = () => {
        progressObject.currState = "ERROR";
    }

    const generateRelativePath = () => {
        let prefix = "";
        if(project){
            prefix = location.pathname.replace("/" + project + "/upload/" + container, "").split("/").filter(res => res !== "").join("/");
        } else {
            prefix = location.pathname.replace("/upload/" + container, "").split("/").filter(res => res !== "").join("/");
        }
        return prefix;
    }

    const handleNavigation = (target: string) => {
        const relativePath = generateRelativePath();
        const prefix = relativePath ? relativePath + "/" + target : target;
        getBlobs(container ? container : target, container ? prefix : "").then((res: any) => {
            setBrowserContext({ ...browserContext, contents: res, showingContainers: false, breadCrumb: generateBreadCrumb(target) })
            const route = location.pathname.endsWith("/") ? location.pathname + target : location.pathname + "/" + target
            history.push(route);
        }).catch((e) => {
            alert("The following error occured:\n\n" + e + "\n\n Please contact your Azure Admin.")
        });
    }

    const listPermittedContainersOnly = () => {
        return appState.rolesAssignments.permissionContainer.map(permittedContainer => {
            return {
                value: {
                    name: permittedContainer.containerName
                }
            }
        });
    }

    const hasReadPermissions = () => {
        const hasStorageAccountReadAccess = appState.rolesAssignments.permissionAllContainers.read;
        const currContainer = appState.rolesAssignments.permissionContainer.find(permittedContainer => {
            return permittedContainer.containerName === container;
        });
        const hasContainerReadAccess = currContainer ? currContainer.read: false;
        return hasStorageAccountReadAccess ? hasStorageAccountReadAccess : hasContainerReadAccess;
    }

    const hasWritePermissions = () => {
        const hasStorageAccountWriteAccess = appState.rolesAssignments.permissionAllContainers.write;

        const currContainer = appState.rolesAssignments.permissionContainer.find(permittedContainer => {
            return permittedContainer.containerName === container;
        });
        const hasContainerWriteAccess = currContainer ? currContainer.write: false;
        return hasStorageAccountWriteAccess ? hasStorageAccountWriteAccess : hasContainerWriteAccess;
    } 

    return (
        <UploadContext.Provider value={{
            browserContext: browserContext,
            setBrowserContext: setBrowserContext,
            generateBreadCrumb: generateBreadCrumb,
            load: load,
            handleNavigation: handleNavigation,
            generateRelativePath: generateRelativePath,
            hasReadPermissions: hasReadPermissions,
            hasWritePermissions: hasWritePermissions,
            upload: upload,
            deleteOperation: deleteOperation,
            download: download
        }} {...props} />
    )
}

function useUploadState() {
    const context = React.useContext(UploadContext)
    return context
}

export { UploadProvider, useUploadState }