/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, {useState} from 'react';
import { CheckboxNavigator as DefaultNavigator, Divider, useTranslation, NavigatorProps, Box, Text, Flex, styled, Label } from '@raytion/sri-frontend-ui-components';
import ShowMoreButton from './ShowMoreButton'
import ExcludeToggle from './ExcludeToggle'

type Option = {
  value: string,
  name?: string,
  count?: number,
  [key:string]: string | number | undefined
}

const SingleNavigator:React.FC <NavigatorProps> = ({size = 5, excludeOption = true, ...props}) => {
  const [ showAll, setShowAll ] = useState(false);
  const t = useTranslation();
  const toggleShowAll = () => setShowAll(showAll => !showAll)


  return (
    <DefaultNavigator {...props} size={500} filterZeroCounts={false}>
      {({ getCheckboxProps, currentOptions, isExclude, toggleExclude, addValue, removeValue, removeFilter }) => {

        if(!currentOptions || currentOptions.length === 0){
          return <div></div>
        }

        const selectedItem = currentOptions.filter(option => {
          return option.selected;
        }).length !== 0;

        const handleOnClick = (value: string, selected: boolean | undefined) => {
          if (!selected) {
            addValue(value)
            return;
          }
          removeValue(value);
        }

        const handleRemoveAll = () => {
          removeFilter();
        }

        return (
          <React.Fragment>
          <Box mb={4}>
            <Flex mb={1} justifyContent="space-between" alignItems="center">
              <Text variant="text" pb={1} fontWeight={700}>
                {props.title} 
              </Text>
              {
                excludeOption &&
                <ExcludeToggle 
                  checked={isExclude} 
                  onChange={toggleExclude} 
                />
              }
            </Flex>
            <Box>
              {
                selectedItem && 
                <Label onClick={handleRemoveAll}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text variant="text" css={{ wordBreak: 'break-word', lineHeight: '20px' }}>
                      {'All options'}
                    </Text>
                  </Flex>
                </Label>
              }
              {currentOptions.slice(0, showAll ? 500 : size).map( ({value, name, count, selected}:{value: string, name?: string, count?: number, selected?: boolean}) => value && (
                <Label onClick={() => {
                  handleOnClick(value, selected)
                  }} key={value}>
                  <Flex justifyContent="space-between" width='100%'>
                    <Text variant="text" css={{ wordBreak: 'break-word', lineHeight: '20px' }} fontWeight={selected ? 600: 400}>
                      {name ? name : value}
                    </Text>
                    <Box>
                      <Badge>
                        {count}
                      </Badge>
                    </Box>
                  </Flex>
                </Label>
              ))}
            </Box>
            {currentOptions.length > 5 && (
              <ShowMoreButton 
                onToggle={toggleShowAll}
                showAll={showAll}
                showMoreLabel={t('Show all')}   
                showLessLabel={t('Show less')}          
              />
            )}
          </Box>
          <Divider backgroundColor="E0E4F2" mb={4} mt={2}  />
          </React.Fragment>
        )
      }}
    </DefaultNavigator>
  )
}

const Badge = styled.div(
  ({theme}) => ({
    ...theme.textStyles.meta,
    marginLeft: theme.space[2],
    paddingLeft: theme.space[2],
    paddingRight: theme.space[2],
    color: theme.colors.primary,
    backgroundColor: '#E0E4F2',
    borderRadius: 50,
    lineHeight: '20px',
  })
)


export default SingleNavigator;