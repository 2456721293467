import React from 'react';
import { Alert, TextButton, useClearFilter, useSearchTearm, useTranslation, AlertText } from '@raytion/sri-frontend-ui-components';

type Notifications = {
  data:any,
  dym: {
    query: string,
    autocorrect: boolean
  }
}

export const Notifications:React.FC <Notifications> = ({data, dym}) => {
  const removeAllFilter = useClearFilter()
  const t = useTranslation()
  const searchTearm = useSearchTearm();
  const setQuery = searchTearm[1];
  const areFiltersSet = data.parameters && (data.parameters.date || data.parameters.nav) ? true : false

  if(dym && dym.query && data && !data.results){
    return (
      <Alert justifyContent="space-between">
        <AlertText>
          {t("Your query \"%1\" returned 0 results.", [<span>{data.parameters.qs}</span>])} 
          {t('Did you mean')}: <TextButton variant="link" onClick={() => setQuery(dym.query)}>{dym.query}</TextButton>
        </AlertText>
        {areFiltersSet && <TextButton variant="subtle" onClick={()=>removeAllFilter()}>{t('Remove all filters')}</TextButton>}
      </Alert>
    )
  }

  if(dym && dym.query){
    return (
      <Alert>
        <AlertText>
          {t('Did you mean')}: <TextButton variant="link" onClick={() => setQuery(dym.query)}>{dym.query}</TextButton>
        </AlertText>
      </Alert>
    )
  }
  
  if(data.parameters && !data.results){
    return (
      <Alert justifyContent="space-between">
        <AlertText>
          {t("Your query \"%1\" returned 0 results.", [<span key={data.parameters.qs}>{data.parameters.qs}</span>])} 
        </AlertText>
        {areFiltersSet && <TextButton variant="subtle" onClick={()=>removeAllFilter()}>{t('Remove all filters')}</TextButton>}
      </Alert>
    )
  }

  return null
}

export default Notifications