import React from 'react';
import { Icon } from '@raytion/sri-frontend-ui-components';
import { TextButton as DefaultTextButton, styled } from '@raytion/sri-frontend-ui-components';

type ShowMoreButtonProp = {
  showLessLabel?: string,
  showMoreLabel?: string,
  onToggle: () => void,
  showAll: boolean,
}

const ShowMoreButton: React.FC <ShowMoreButtonProp> = ({
  showLessLabel = 'Show Less',
  showMoreLabel = 'Show All',
  onToggle = () => {},
  showAll
}) => {
  return(
    <TextButton 
      onClick={onToggle}
      mt={1}
      reverse
      icon={ showAll ? (
        <Icon size={12} className="fal fa-minus" />
      ):(
        <Icon size={12} className="fal fa-plus" />
      )}
    >
      {showAll ? showLessLabel : showMoreLabel}
    </TextButton> 
  )
}


const TextButton = styled(DefaultTextButton)(
  ({theme}) => ({ 
    ...theme.textStyles.meta,
    fontWeight: 700,
    color: theme.colors.primary,
  })
)

export default ShowMoreButton