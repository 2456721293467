import './index.css';
import { authContext } from './config/msalConfig';

let isInteracting = false;

async function loginUser() {
  try {
    const accounts = authContext.getAllAccounts();

    if (accounts.length > 0) {
      authContext.handleRedirectPromise();
      const account = authContext.getAccountByHomeId(accounts[0].homeAccountId);
      authContext.setActiveAccount(account);
      require('./indexApp.js');
    } else {
      if (!isInteracting) {
        isInteracting = true;
        await authContext.handleRedirectPromise();
        initiateLogin();
      } else {
        await waitAndRetryLogin();
      }
    }
  } catch (error) {
    console.error('Error during login:', error);
  }
}

async function initiateLogin() {
  try {
    authContext.loginRedirect({ scopes: ['openid', 'profile', 'user.read', 'User.Read'] });
  } catch (error) {
    console.error('Error initiating login:', error);
  }
}

async function waitAndRetryLogin() {
  await new Promise(resolve => setTimeout(resolve, 500));
  await loginUser();
}

loginUser();

