import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { config } from './config/config';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import { useAppState } from './context/AppProvider'

const App: React.FC = () => {
  const [state] = useAppState();
  return (
    <Router basename={config.route ? config.route.basename : ''}>
      {!state.graphToken ? <UnauthenticatedApp /> : <AuthenticatedApp />}
    </Router>
  )
}

export default App;
